import { crudService } from '../_services/'
import { history } from '../utils'
import { toast } from 'react-toastify'
import { stringUtils } from 'utils'
import { obraAction } from '_actions'

export const empenhoAction = {
  getEmpenho,
  getEmpenhoById,
  onChangeProps,
  editEmpenhoInfo,
  editEmpenhoRealizaPagamento,
  changeDetailsEmpenho,
  createEmpenho,
  deleteEmpenhoById,
  clear,
  notIsLoading,
  isLoading
}

let filterModel = {
  Page: 0,
  Limit: 10,
  Term: ''
};

function getEmpenho(filterModel) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empenhos' + stringUtils.serialize(filterModel);
    crudService
      .get(apiEndpoint)
      .then((response) => {
        dispatch(changeEmpenhosList(response.data.items, response.data.totalRows))
        dispatch(notIsLoading())
      })
      .catch((err) => {
        console.log(err)
      })
  }
}

function createEmpenho(payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'empenhos'
    crudService
      .post(apiEndpoint, payload)
      .then((response) => {
        if (response.request != undefined && response.request.status === 200) {
          toast.success('Feito! Cadastro realizado com sucesso! 😎')
          dispatch(clear())
          dispatch(notIsLoading())
          history.push('/empenhos')
        } else {
          //     toast.err('Oops! Erro ao cadastrar órgão! 😥')
        }
      })
      .catch((err) => {
        // console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //    toast.err('Oops! Erro ao cadastrar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'CREATE_EMPENHO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'CREATE_EMPENHO_FAILURE', error }
  }
}

export function clear() {
  return {
    type: 'EMPENHO_CLEAR'
  }
}

function editEmpenhoInfo(id, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    dispatch(request(payload))
    let apiEndpoint = 'empenhos/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          //dispatch(success(payload))
          dispatch(notIsLoading())
          history.push('/empenhos')
        } else {
          //  toast.error('Oops! Erro ao alterar órgão! 😥')
        }
      })
      .catch((err) => {
        //console.log('err', err)
        dispatch(failure(err))
        dispatch(notIsLoading())
        //  toast.error('Oops! Erro ao alterar órgão! 😥')
      })
  }

  function request(payload) {
    return { type: 'EDIT_EMPENHO_REQUEST', payload }
  }

  function failure(error) {
    return { type: 'EDIT_EMPENHO_FAILURE', error }
  }
}

function getEmpenhoById(id) {
  return (dispatch) => {
    let apiEndpoint = 'empenhos/' + id
    crudService.get(apiEndpoint).then((response) => {
      dispatch(editEmpenhosDetails(response.data))
    })
  }
}

function editEmpenhoRealizaPagamento(id, idOrgao, idObra, payload) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empenhos/pagar/' + id
    crudService
      .put(apiEndpoint, payload)
      .then((response) => {
        if (response && response.request.status === 200) {
          dispatch(notIsLoading())
          dispatch(getEmpenho({ IdOrgao: idOrgao }))
          if (idObra && idObra != 0) {
            dispatch(obraAction.getObraById(idObra))
          }
        } else {
          dispatch(notIsLoading())
        }
      })
      .catch((err) => {
        dispatch(failure(err))
        dispatch(notIsLoading())
      })
  }

  function failure(error) {
    return { type: 'EDIT_EMPENHO_FAILURE', error }
  }
}

function onChangeProps(props, event) {
  return (dispatch) => {
    if (props === 'imagem') {
      dispatch(handleOnChangeProps(props, event))
    } else if (event.target.type === 'checkbox') {
      dispatch(handleOnChangeProps(props, event.target.checked))
    } else {
      dispatch(handleOnChangeProps(props, event.target.value))
    }
  }
}
function changeDetailsEmpenho(empenho) {
  return (dispatch) => {
    if (empenho) {
      dispatch(onChangeDetailsEmpenho(empenho))
    } else {
      clear()
    }
  }
}

export function onChangeDetailsEmpenho(empenho) {
  return [
    {
      type: 'CHANGE_DETAILS_EMPENHO',
      id: empenho.id,
      nome: empenho.nome
    }
  ]
}

function deleteEmpenhoById(id) {
  return (dispatch) => {
    dispatch(isLoading())
    let apiEndpoint = 'empenhos/' + id
    crudService.deleteDetail(apiEndpoint).then((response) => {
      dispatch(deleteEmpenhosDetails())
      history.push('/empenhos')
      dispatch(getEmpenho(filterModel, true))
      dispatch(notIsLoading())
    })
  }
}
export function changeEmpenhosList(empenho, totalRows) {
  return {
    type: 'FETCHED_ALL_EMPENHO',
    empenho: empenho,
    totalRegistros: totalRows
  }
}
export function handleOnChangeProps(props, value) {
  return {
    type: 'EMPENHO_HANDLE_ON_CHANGE',
    props: props,
    value: value
  }
}
export function editEmpenhosDetails(empenho) {
  return {
    type: 'EMPENHO_DETAIL',
    id: empenho.id,
    isLoading: empenho.isLoading,
    caminhoArquivo: empenho.caminhoArquivo,
    caminhoNotaFiscal: empenho.caminhoNotaFiscal,
    idOrgao: empenho.idOrgao,
    dataCadastro: empenho.dataCadastro,
    dataPagamento: empenho.dataPagamento,
    orgao: empenho.orgao,
    idEmpresa: empenho.idEmpresa,
    empresa: empenho.empresa,
    obrasEmpenhos: empenho.obrasEmpenhos,
    excluido: empenho.excluido
  }
}
export function createUserInfo() {
  return {
    type: 'EMPENHO_CREATED_SUCCESSFULLY'
  }
}
export function deleteEmpenhosDetails() {
  return {
    type: 'DELETED_EMPENHO_DETAILS'
  }
}
export function isLoading() {
  return {
    type: 'EMPENHO_ISLOADING'
  }
}

export function notIsLoading() {
  return {
    type: 'EMPENHO_NOTISLOADING'
  }
}


