const initialState = {
  totalRegistros: 0,
  isLoading: false,
  obraMedicao: [],
  open: false,
  id: '',
  data: '',
  idObra: 0,
  obra: [],
  idUsuario: 0,
  usuario: [],
  excluido: false,
  valor: 0,
  titulo: '',
  situacao: '',
  obrasMedicoesEtapasItens: [],
  obrasMedicoesAnexos: [],
  obrasOcorrencias: []
}

export function obraMedicao(state = initialState, action) {
  switch (action.type) {
    case 'CREATE_OBRA_MEDICAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'CREATE_OBRA_MEDICAO_SUCCESS':
      return {
        ...state,
        id: action.obraMedicao.id,
        isLoading: false,
        data: action.obraMedicao.data,        
        idObra: action.obraMedicao.idObra,
        obra: action.obraMedicao.obra,
        idUsuario: action.obraMedicao.idUsuario,
        usuario: action.obraMedicao.usuario,
        excluido: action.obraMedicao.excluido,
        titulo: action.obraMedicao.titulo,
        valor: action.obraMedicao.valor,
        situacao: action.obraMedicao.situacao,
        obrasMedicoesEtapasItens: action.obraMedicao.obrasMedicoesEtapasItens,
        obrasMedicoesAnexos: action.obraMedicao.obrasMedicoesAnexos,
        obrasOcorrencias: action.obraMedicao.obrasOcorrencias
      }
    case 'CREATE_OBRA_MEDICAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'EDIT_OBRA_MEDICAO_REQUEST':
      return {
        ...state,
        isLoading: true
      }
    case 'EDIT_OBRA_MEDICAO_SUCCESS':
      return {
        ...state,
        id: action.obraMedicao.id,
        isLoading: false,
        data: action.obraMedicao.data,
        idObra: action.obraMedicao.idObra,
        obra: action.obraMedicao.obra,
        idUsuario: action.obraMedicao.idUsuario,
        usuario: action.obraMedicao.usuario,
        excluido: action.obraMedicao.excluido,
        titulo: action.obraMedicao.titulo,
        valor: action.obraMedicao.valor,
        situacao: action.obraMedicao.situacao,
        obrasMedicoesEtapasItens: action.obraMedicao.obrasMedicoesEtapasItens,
        obrasOcorrencias: action.obraMedicao.obrasOcorrencias,
        obrasMedicoesAnexos: action.obraMedicao.obrasMedicoesAnexos
      }
    case 'EDIT_OBRA_MEDICAO_FAILURE':
      return {
        ...state,
        isLoading: false,
        error: action.error
      }
    case 'FETCHED_ALL_OBRA_MEDICAO':
      return {
        ...state,
        obraMedicao: action.obraMedicao,
        totalRegistros: action.totalRegistros
      }
    case 'OBRA_MEDICAO_CLEAR':
      return initialState
    case 'OBRA_MEDICAO_ESTABELECIMENTO_ADD_SUCCESS':
      return {
        ...state,
        obraMedicao: [...state.obraMedicao, action.data.obraMedicao]
      }
    case 'OBRA_MEDICAO_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        data: action.data,
        idObra: action.idObra,
        obra: action.obra,
        idUsuario: action.idUsuario,
        usuario: action.usuario,
        excluido: action.excluido,
        titulo: action.titulo,
        valor: action.valor,
        situacao: action.situacao,
        obrasMedicoesEtapasItens: action.obrasMedicoesEtapasItens,
        obrasOcorrencias: action.obrasOcorrencias,
        obrasMedicoesAnexos: action.obrasMedicoesAnexos
      }
    case 'OBRA_MEDICAO_HANDLE_ON_CHANGE':
      return {
        ...state,
        [action.props]: action.value
      }
    case 'OBRA_MEDICAO_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'OBRA_MEDICAO_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
