import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  CardActions,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Grid,
  Typography
} from '@mui/material'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import {
  obraAction
} from '../../_actions'
import { toast } from 'react-toastify';
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import { stringUtils } from '../../utils'

export const ObraMotivoAlteracaoHorasRecebimentoOrcamentosModal = ({ modal, toggle, props, isObra, isPlanoTrabalho, isReparoPrevencao, payloadObra, setPayloadObra }) => {

  const formRef = React.createRef(null);

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;

  const textObra = 'a Obra';
  const textCompraMaterial = 'a Compra de Material';
  const textPlanoTrabalho = 'o Plano de Trabalho';
  const textReparoPrevencao = 'o Reparo/Prevenção';

  const handleChange = (prop) => (event) => {
    dispatch(obraAction.onChangeProps(prop, event))
  }

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const handleClose = () => {
    toggle();
  }

  const handleSubmit = () => {
    if (props.obra.motivoMudancaHorasRecebimentoOrcamentos && props.obra.motivoMudancaHorasRecebimentoOrcamentos != null && props.obra.motivoMudancaHorasRecebimentoOrcamentos != '') {

      if (props.obra.id) {
        dispatch(obraAction.editObraInfo(props.obra.id, payloadObra, idOrgao, isObra, isPlanoTrabalho, isReparoPrevencao))
      } else {
        dispatch(obraAction.createObra(payloadObra, idOrgao, isObra, isPlanoTrabalho, isReparoPrevencao));
      }

      toggle();
    } else {
      toast.error('É necessário informar um motivo')
    }

  };

  function InsertText() {
    return (
      <Typography variant="h6">
        <b>{'Editar horas de recebimento de orçamentos'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    return <InsertText />
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle align="right" style={{ paddingTop: 0, marginTop: 0, marginBottom: 0, paddingBottom: 0 }}>
        <Button
          variant="contained"
          style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
          onClick={() => handleClose()}>
          <CloseIcon />
        </Button>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0 }}>
          <ValidatorForm
            style={{ marginTop: 0, paddingTop: 0 }}
            ref={formRef}
            id="formUsuario"
            onSubmit={(event) => handleSubmit(event)}
          >
            <CardHeader
              style={{ marginTop: 0, paddingTop: 0 }}
              subheader={`Você escolheu editar ${isReparoPrevencao ? textReparoPrevencao : isPlanoTrabalho ? textPlanoTrabalho : isObra ? textObra : textCompraMaterial} para o valor informado`}
              title={<SegHeader />}
            />
            <Divider />
            <CardContent lg={12} md={12} xl={12} xs={12}>
              <TextValidator
                className={classes.textField}
                validators={['required']}
                errorMessages={['Campo obrigatório!']}
                fullWidth
                minRows={2}
                multiline
                InputLabelProps={{
                  shrink: true
                }}
                id="motivo"
                label="Informe o motivo pelo qual deseja alterar o valor deste campo"
                margin="normal"
                onInput={stringUtils.toInputUppercase}
                onChange={handleChange('motivoMudancaHorasRecebimentoOrcamentos')}
                value={props.obra.motivoMudancaHorasRecebimentoOrcamentos}
                variant="outlined"
              />
            </CardContent>
            <Divider />
            <CardActions>
              <Grid container style={{
                display: 'flex',
                alignItems: 'end',
                flexDirection: 'row',
                textAlign: 'end',
                justifyContent: 'end'
              }}>
                <Button
                  variant="outlined"
                  style={{ color: '#000', borderColor: '#000' }}
                  onClick={toggle}
                >
                  Cancelar
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  style={{ backgroundColor: '#000' }}
                  disabled={props.obra.isLoading}
                  startIcon={<SaveIcon />}
                  endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                >
                  {props.obra.isLoading ? `Alterando ${isReparoPrevencao ? textReparoPrevencao : isPlanoTrabalho ? textPlanoTrabalho : isObra ? textObra : textCompraMaterial}...` : 'Alterar e salvar'}
                </Button>
              </Grid>
            </CardActions>
          </ValidatorForm>
        </Card>
      </DialogContent>
    </Dialog >
  )
}
