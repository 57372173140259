import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, obraAction } from '../../_actions'
import PropTypes from 'prop-types'
import { GoogleApiWrapper } from 'google-maps-react';
import moment from 'moment'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import {
  Box,
  Grid,
  Paper,
  Typography,
} from '@mui/material'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import { styled } from '@mui/material/styles';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#015192'
  },
}));

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomGrid2 = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: 4
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const ObraSummaryCard = (props) => {

  const { match: { params } } = props;
  const { idObra, isOrcamento, latitude, longitude } = props
  const dispatch = useDispatch()
  const [endereco, setEndereco] = useState('');

  useEffect(() => {

    dispatch(obraAction.clear());

    if (idObra) {
      dispatch(obraAction.getObraById(idObra))
    }
  }, [])

  const empenhos = useSelector((state) => state.obra?.obrasEmpenhos)

  const verifyObraHasEmpenho = () => {
    return empenhos?.some(ts => ts.idObra == parseInt(idObra) && ts.excluido != true);
  }

  useEffect(() => {
    if (props.google && latitude && longitude) {
      const geocoder = new props.google.maps.Geocoder();
      geocoder.geocode(
        { location: { lat: latitude, lng: longitude } },
        (results, status) => {
          if (status === 'OK' && results[0]) {
            // Verifica se o endereço contém um Plus Code
            const formattedAddress = results[0].formatted_address;
            if (formattedAddress.includes('+')) {
              // Se o endereço contém um Plus Code, você pode definir o endereço como não informado ou um texto alternativo
              setEndereco('Endereço não disponível.');
            } else {
              setEndereco(formattedAddress);
            }
          } else {
            console.error('Geocode error: ', status);
          }
        }
      );
    }
  }, [props.google, latitude, longitude]);

  //verifica se a empresa do usuário logado é a vencedora para a obra, dentro dos orçamentos
  function isWinner(obra) {
    const idEmpresa = authActions.isEmpresa() ? props.authentication?.user?.usuariosEmpresas[0]?.idEmpresa : 0

    return obra.obrasOrcamentos?.some(orcamento => orcamento.idEmpresa === idEmpresa && orcamento.status === 1);
  }

  return (
    <Grid item lg={12} md={12} xl={12} xs={12}>
      {idObra &&
        <Paper elevation={3} style={{ marginBottom: 6, backgroundColor: '#f7f7f7', paddingBottom: 4 }}>
          <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={12} lg={12} md={12} xs={12} align="center">
              {
                (authActions.isEmpresa() && !isOrcamento) ?
                  isWinner(props.obra) ?
                    <span style={{ backgroundColor: '#00cc66', borderRadius: 8, padding: 4, color: '#fff', fontSize: 16, marginRight: 4, fontWeight: 400 }}>
                      <WarningAmberOutlinedIcon style={{ verticalAlign: 'middle', marginRight: 2, fontSize: 'medium' }} /> VOCÊ É A EMPRESA VENCEDORA DESTA OBRA
                    </span>
                    :
                    <span style={{ backgroundColor: '#ef5350', borderRadius: 8, padding: 4, color: '#fff', fontSize: 16, marginRight: 4, fontWeight: 400 }}>
                      <WarningAmberOutlinedIcon style={{ verticalAlign: 'middle', marginRight: 2, fontSize: 'medium' }} /> VOCÊ NÃO É A EMPRESA VENCEDORA DESTA OBRA
                    </span>
                  :
                  null
              }


            </CustomGrid>
            <CustomGrid item xl={3} lg={3} md={3} xs={12} align="left">
              <CustomTypography variant="overline" style={{ fontSize: 15 }} ><strong>RESUMO DA OBRA</strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={3} lg={3} md={3} xs={12} align="right">
              <CustomTypography variant="overline"><strong>USUÁRIO DE CRIAÇÃO: </strong>{props.obra.usuario?.nome?.toUpperCase()}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={3} lg={3} md={3} xs={12} align="right">
              <CustomTypography variant="overline"><strong>DATA DE INÍCIO: </strong>{props.obra.dataInicio ? moment(props.obra.dataInicio).format("DD/MM/yyyy") : ''}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={3} lg={3} md={3} xs={12} align="right">
              <CustomTypography variant="overline"><strong>PREVISÃO DE CONCLUSÃO: </strong>{props.obra.dataTermino ? moment(props.obra.dataTermino).format("DD/MM/yyyy") : ''}</CustomTypography>
            </CustomGrid>
          </Grid>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={1} lg={1} md={1} xs={12} align="left">
              <CustomTypography variant="overline"><strong>TÍTULO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={6} lg={6} md={6} xs={12} align="left">
              <CustomTypography variant="overline">#{props.obra?.id} {props.obra?.titulo}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="right" >
              <CustomTypography variant="overline"><strong>VALOR MÁXIMO DA OBRA: </strong></CustomTypography>
              <CustomTypography variant="overline">{props.obra.valorReferenciaComBdi ? Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL'
              }).format(props.obra.valorReferenciaComBdi) : 'R$ --,--'}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={1} lg={1} md={1} xs={12} align="left">
              <CustomTypography variant="overline"><strong>DESCRITIVO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={6} lg={6} md={6} xs={12} align="left">
              <CustomTypography variant="overline">{props.obra?.descritivo}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="right" >
              <CustomTypography variant="overline"><strong>BDI: </strong></CustomTypography>
              <CustomTypography variant="overline">{props.obra.bdi ? props.obra.bdi + '%' : 'SEM BDI'}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, paddingRight: 12 }}>
            <CustomGrid item xl={1} lg={1} md={1} xs={12} align="left">
              <CustomTypography variant="overline"><strong>ÓRGÃO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={6} lg={6} md={6} xs={12} align="left">
              <CustomTypography variant="overline">{props.obra.orgao?.nome?.toUpperCase()}</CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={5} lg={5} md={5} xs={12} align="right" >
              <CustomTypography variant="overline"><strong>Possui empenho: </strong></CustomTypography>
              <CustomTypography variant="overline">{verifyObraHasEmpenho() ? 'SIM' : 'NÃO'}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
          {props.obra.usuarioMudancaSituacao &&
            <CustomGrid2 container spacing={2} style={{ paddingLeft: 10 }}>
              <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
                <CustomTypography variant="overline"><strong>USUÁRIO DE APROVAÇÃO: </strong></CustomTypography>
              </CustomGrid>
              <CustomGrid item xl={10} lg={10} md={10} xs={12} align="left">
                <CustomTypography variant="overline">{props.obra.usuarioMudancaSituacao.nome?.toUpperCase()}</CustomTypography>
              </CustomGrid>
            </CustomGrid2>
          }
          {props.obra.dataMudancaSituacao &&
            <CustomGrid2 container spacing={2} style={{ paddingLeft: 10 }}>
              <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
                <CustomTypography variant="overline"><strong>DATA DA APROVAÇÃO: </strong></CustomTypography>
              </CustomGrid>
              <CustomGrid item xl={10} lg={10} md={10} xs={12} align="left">
                <CustomTypography variant="overline">{moment(props.obra.dataMudancaSituacao).format("DD/MM/yyyy HH:mm:ss")}</CustomTypography>
              </CustomGrid>
            </CustomGrid2>
          }
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 10, alignItems: 'center' }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>CONCLUSÃO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={10} lg={10} md={10} xs={12} align="left">
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '92%', mr: 3, ml: 1 }}>
                  <BorderLinearProgress variant="determinate" value={props.obra.percentualConclusaoObra != 0 ? props.obra.percentualConclusaoObra : 0} />
                </Box>
                <Box sx={{ minWidth: 38 }}>
                  <CustomTypography variant="overline">{`${props.obra.percentualConclusaoObra != 0 ? props.obra.percentualConclusaoObra : 0}%`}</CustomTypography>
                </Box>
              </Box>
            </CustomGrid>
          </CustomGrid2>
          <CustomGrid2 container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
            <CustomGrid item xl={2} lg={2} md={2} xs={12} align="left">
              <CustomTypography variant="overline"><strong>ENDEREÇO: </strong></CustomTypography>
            </CustomGrid>
            <CustomGrid item xl={9} lg={9} md={9} xs={12} align="left">
              <CustomTypography variant="overline">{endereco && endereco != '' ? endereco : 'Não informado'}</CustomTypography>
            </CustomGrid>
          </CustomGrid2>
        </Paper>
      }
    </Grid>
  )
}

ObraSummaryCard.propTypes = {
  classes: PropTypes.object.isRequired,
  idObra: PropTypes.number.isRequired,
  isOrcamento: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number
}

const mapStateToProps = (state) => {
  return state
}

const connectedObraSummaryCard = withRouter(
  connect(mapStateToProps)(withStyles(styles)(ObraSummaryCard))
);

// para conseguir obter o endereço, através da API, é necessária a chave
export default GoogleApiWrapper({
  apiKey: 'AIzaSyC1QwWDv0BApsU0gjwIqEgmns_8RX2s-AU',
})(connectedObraSummaryCard);
