import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withStyles } from '@mui/styles'
import HelpIcon from '@mui/icons-material/Help';
import {
  Backdrop,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Input,
  LinearProgress,
  MenuItem,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableContainer,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  SelectValidator,
  TextValidator
} from 'react-material-ui-form-validator'
import { notificacaoAction, notificacaoFiltroAction } from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import { Page, ImageUploader } from 'components'
import { uploadImage } from '_services'
import { errorsUploadImage } from 'utils'
import SaveIcon from '@mui/icons-material/Save';
import PersonIcon from '@mui/icons-material/Person';
import getInitials from 'utils/getInitials'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import SendIcon from '@mui/icons-material/Send';
import { AccessControl } from '../../components/Utils/AccessControl'
import DeleteIcon from '@mui/icons-material/Delete'
import FilterListIcon from '@mui/icons-material/FilterList'
import { ModalNotificacaoFiltrosItens } from './modalNotificacaoFiltrosItens'
import { ModalNotificacaoUsuarios } from './modalNotificacaoUsuarios'
import moment from 'moment'
import { ModalNotificacaoTestes } from './modalNotificacaoTestes'
import CustomButton from '../../components/CustomButton/CustomButton'
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'space-between'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const NotificacaoEdit = (props) => {

  const dispatch = useDispatch()
  const { classes, notificacao, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)
  const confirm = useConfirm()
  const [, forceUpdate] = React.useState(0);
  const [ready, setReady] = React.useState(false);

  const { notificacaoUsuario } = useSelector(
    (state) => state.notificacaoUsuario
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  useEffect(() => {

    dispatch(notificacaoAction.clear())

    if (params.id) {
      dispatch(notificacaoAction.getNotificacaoById(params.id))

    } else {
      dispatch(notificacaoAction.clear())
      setImagem(null)
    }

  }, []);

  useEffect(() => {

    if (params.id) {
      dispatch(notificacaoAction.getNotificacaoById(params.id))
    } else {
      dispatch(notificacaoAction.clear())
      setImagem(null)
    }

  }, [props.notificacaoFiltro.notificacaoFiltro && props.notificacaoFiltro.notificacaoFiltro.length])

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...notificacao,
        caminhoImagem: null
      }
      dispatch(
        notificacaoAction.editNotificacaoInfo(notificacao.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(notificacaoAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const handleClickOpenModalNotificacaoTestes = () => {
    dispatch(notificacaoAction.openModalNotificacaoTestes(true));
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...notificacao,
          caminhoImagem: response.toString()
        }

        if (notificacao.id !== 0) {
          dispatch(
            notificacaoAction.editNotificacaoInfo(notificacao.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(notificacaoAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  useEffect(() => {

  }, [ready, props.notificacao.isLoading])

  useEffect(() => {
    setImagem(notificacao.caminhoImagem)
  }, [notificacao.caminhoImagem, open]);

  useEffect(() => {
    //  forceUpdate(n => !n);
  }, [open, imagem]);

  const handleChange = (prop) => (event) => {
    dispatch(notificacaoAction.onChangeProps(prop, event))
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      dataHora: moment(props.notificacao.dataHora).toISOString(),
      titulo: props.notificacao.titulo,
      descricao: props.notificacao.descricao,
      caminhoImagem: props.notificacao.caminhoImagem,
      acao: props.notificacao.acao,
      chave: props.notificacao.chave
    }

    if (params.id) {
      dispatch(notificacaoAction.editNotificacaoInfo(params.id, payload, true))
    } else {
      dispatch(notificacaoAction.createNotificacao(payload))
    }

  };

  const handleSubmitNotificacaoTeste = (event) => {

    let payload = {
      id: params.id,
      dataHora: props.notificacao.dataHora,
      titulo: props.notificacao.titulo,
      descricao: props.notificacao.descricao,
      caminhoImagem: props.notificacao.caminhoImagem,
      acao: props.notificacao.acao,
      chave: props.notificacao.chave
    }

    if (params.id) {
      dispatch(notificacaoAction.editNotificacaoInfo(params.id, payload, true))
    } else {
      dispatch(notificacaoAction.createNotificacao(payload))
    }

  };

  const handleSend = (event) => {

    if (props.notificacao.id) {
      dispatch(notificacaoAction.sendNotificacao(props.notificacao.id))
    }

  };

  const handleClick = (id) => {
    confirm({
      title: 'Você deseja excluir esta notificação?',
      description: 'Essa operação é irreversível',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(notificacaoFiltroAction.deleteNotificacaoFiltroById(id))
    })
  }

  const handleClickOpenModalNotificacaoFiltrosItens = () => {
    dispatch(notificacaoFiltroAction.clearInfo())
    dispatch(notificacaoAction.openModalNotificacaoFiltrosItens(true))
  }

  const handleClickOpenModalNotificacaoUsuarios = () => {
    dispatch(notificacaoAction.openModalNotificacaoUsuarios(true))
  }

  function switchFiltro(val) {
    var answer = "";
    switch (val) {
      case "usuariosAdmin":
        answer = "Usuários administradores cadastrados no sistema";
        break;
      case "usuariosGestorOrgaoPublico":
        answer = "Usuários gestores de órgão público cadastrados no sistema";
        break;
      case "usuariosEmpresa":
        answer = "Usuários de empresas cadastrados no sistema";
        break;
      case "usuariosSistema":
        answer = "Usuários do tipo sistema cadastrados no sistema";
        break;
      default:
        answer = "";
    }
    return answer;
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Nova Notificação'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Editar Notificação'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Notificações - Editar Notificação'
          : 'Gerência de Notificações - Nova Notificação'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <Grid item md={12} xs={12}>
            <Backdrop className={classes.backdrop} open={open}>
              <Grid
                container
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="nowrap"
              >
                <Grid item>
                  <CircularProgress style={{ color: '#000' }} />
                </Grid>
                <Grid item>
                  <Typography variant="h5" color="textPrimary">
                    Enviando imagem...
                  </Typography>
                </Grid>
              </Grid>
            </Backdrop>
            <ImageUploader
              images={images}
              onChangeImage={onChangeImage}
              onError={errorsUploadImage}
              acceptType={['jpeg', 'jpg']}
              maxFileSize={2000000}
              resolutionWidth={1100}
              resolutionHeight={1100}
              resolutionType={"less"}
              removeImage={removeImage}
              props={notificacao}
              imagem={imagem}
              getInitials={getInitials}
              classes={classes}
              rest={rest}
              className={className}
            />
          </Grid>
        </Grid>
        <Grid item lg={8} md={6} xl={9} xs={12}>
          <Grid>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formNotificacao"
                onSubmit={(event) => handleSubmit(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Notificações"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  <Grid container spacing={2}>
                    {(props.notificacao.id !== 0 && props.notificacao.dataHora != null ?
                      <Grid item lg={4} md={6} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="dataHora"
                          label="Data/Hora"
                          margin="normal"
                          value={moment(props.notificacao.dataHora).format("DD/MM/YYYY HH:mm")}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      :
                      null
                    )}
                    {(props.notificacao.id !== 0 && props.notificacao.dataHoraEnvio != null ?
                      <Grid item lg={4} md={6} xl={4} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="dataHoraEnvio"
                          label="Data/Hora Envio"
                          margin="normal"
                          value={moment(props.notificacao.dataHoraEnvio).format("DD/MM/YYYY HH:mm")}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      :
                      null
                    )}
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="titulo"
                        label="Título *"
                        margin="normal"
                        onChange={handleChange('titulo')}
                        value={props.notificacao.titulo}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TextValidator
                        className={classes.textField}
                        fullWidth
                        id="descricao"
                        label="Descrição *"
                        margin="normal"
                        onChange={handleChange('descricao')}
                        value={props.notificacao.descricao}
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['Campo obrigatório!']}
                      />
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={12}>
                      <SelectValidator
                        className={classes.textField}
                        validators={['required']}
                        label="Ação"
                        labelId="acao"
                        errorMessages={['Campo obrigatório!']}
                        value={props.notificacao.acao || 'Home'}
                        onChange={handleChange('acao')}
                        name="acao"
                        fullWidth
                        inputProps={{
                          name: "Acao",
                          id: "acao"
                        }}
                        input={<Input id="acao" />}
                        margin="normal"
                        variant="outlined"
                        id="acao"
                      >
                        <MenuItem value={'DetalheNotificacao'}>Conteúdo da Notificação</MenuItem>
                        <MenuItem value={'Home'}>Tela inicial</MenuItem>
                        <MenuItem value={'OpenLink'}>Abrir link externo</MenuItem>
                      </SelectValidator>
                    </Grid>
                    {props.notificacao.acao == 'OpenLink' ?
                      <Grid item lg={8} md={8} xl={8} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          label="Chave"
                          margin="normal"
                          onChange={handleChange('chave')}
                          value={props.notificacao.chave}
                          variant="outlined"
                        />
                      </Grid>
                      :
                      <Grid item lg={8} md={8} xl={8} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="chave"
                          label="Chave"
                          margin="normal"
                          type="number"
                          onChange={handleChange('chave')}
                          value={props.notificacao.chave}
                          variant="outlined"
                        />
                      </Grid>
                    }
                  </Grid>
                </CardContent>
                <Divider />
                <CardActions >
                  <Grid item lg={12} md={12} xl={12} xs={12}>
                    <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                      * Campos obrigatórios
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'right',
                    flexDirection: 'row',
                    textAlgin: 'right',
                    justifyContent: 'right'
                  }}>
                    <CustomButton
                      onClick={() => history.push('/Notificacoes')}
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                    >
                      Cancelar
                    </CustomButton>
                    <CustomButton
                      dark={true}
                      type="submit"
                      variant="contained"
                      disabled={props.notificacao.isLoading}
                      startIcon={<SaveIcon />}
                      endIcon={props.notificacao.isLoading && <CircularProgress size={24} />}
                    >
                      {params.id ? (props.notificacao.isLoading ? 'Atualizando notificação...' : 'Atualizar') : (props.notificacao.isLoading ? 'Salvando notificação...' : 'Salvar')}
                    </CustomButton>
                  </Grid>
                </CardActions>
              </ValidatorForm>
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={4} md={6} xl={3} />
        {(props.notificacao.id !== 0 ?
          <Grid item lg={8} md={6} xl={9} xs={12}>
            <Grid item md={12} xs={12}>
              <Card>
                <ValidatorForm
                  className={classes.form}
                  ref={formRef}
                  id="formNotificacao"
                  onSubmit={(event) => handleSubmit(event)}
                >
                  <CardHeader
                    subheader="Defina quais usuários receberão a notificação"
                    title={
                      <Typography variant="h5">
                        <b>{'Filtro de Usuários'}</b>
                      </Typography>
                    }
                  />
                  <Divider />
                  <CardContent lg={12} md={12} xl={12} xs={12} style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <Grid item lg={8} md={8} xl={8} xs={8} style={{ justifyContent: 'center', alignContent: 'center' }}>
                      <CustomButton style={{ width: '150px', alignContent: 'center' }} name="btnNovo" id="btnNovo" className="btn" component='a' onClick={() => handleClickOpenModalNotificacaoFiltrosItens()}>Novo&nbsp;<FontAwesomeIcon icon={faPlus} /></CustomButton>
                    </Grid>
                    <Grid item lg={4} md={4} xl={4} xs={4} style={{
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlign: 'right',
                      justifyContent: 'right'
                    }}>
                      {(props.notificacao.dataHoraEnvio != null ?
                        <>
                          <Typography>
                            Taxa de Abertura {(props.notificacao ? props.notificacao.taxaAbertura : 0)}% de {(props.notificacao ? props.notificacao.totalNotificacoesUsuarios : 0)}
                          </Typography>
                          <LinearProgress color="secondary" variant="determinate" value={props.notificacao.taxaAbertura} />
                        </>
                        :
                        <Typography>
                          Usuários que receberão a notificação: {(props.notificacao ? props.notificacao.totalNotificacoesUsuarios : 0)}
                        </Typography>
                      )}
                    </Grid>
                  </CardContent>
                  <Grid item lg={12} md={12} xl={12} xs={12} style={{ justifyContent: 'left', alignContent: 'center', marginLeft: '12px' }}>
                    <TableContainer>
                      <Table>
                        <TableBody>
                          {undefined !== props.notificacaoFiltro.notificacaoFiltro && props.notificacaoFiltro.notificacaoFiltro.length
                            ? props.notificacaoFiltro.notificacaoFiltro.map((n) => {
                              return (
                                <TableRow hover key={n.id} lg={12} md={12} xl={12} xs={12} >
                                  <TableCell component="th" scope="row" lg={12} md={12} xl={12} xs={12} >
                                    {switchFiltro(n.filtro)}
                                  </TableCell>
                                  <TableCell align="right" lg={12} md={12} xl={12} xs={12} style={{ display: 'flex' }}>
                                    <AccessControl
                                      rule={'notificacoes.delete'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <IconButton
                                          aria-label="Delete"
                                          onClick={() => handleClick(n.id)}
                                          size="small"
                                        >
                                          <Tooltip title="Excluir">
                                            <DeleteIcon />
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    />
                                    <AccessControl
                                      rule={'notificacoes.edit'} //permissão necessária para acessar conteúdo
                                      yes={() => (
                                        <IconButton
                                          aria-label="Edit"
                                          component="a"
                                          onClick={() => handleClickOpenModalNotificacaoFiltrosItens()}
                                          size="small"
                                          disabled={props.notificacaoFiltro.isLoading}
                                          startIcon={<SaveIcon />}
                                          endIcon={props.notificacaoFiltro.isLoading && <CircularProgress size={24} />}
                                        >
                                          <Tooltip title="Editar Filtros">
                                            <Tooltip>
                                              <FilterListIcon />
                                            </Tooltip>
                                          </Tooltip>
                                        </IconButton>
                                      )}
                                    />
                                  </TableCell>
                                </TableRow>
                              )
                            })
                            : null}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                  <Divider />
                  <CardActions >
                    <Grid item lg={12} md={12} xl={12} xs={12} style={{
                      display: 'flex',
                      alignItems: 'right',
                      flexDirection: 'row',
                      textAlign: 'right',
                      justifyContent: 'right'
                    }}>
                      <CustomButton
                        dark={true}
                        variant="contained"
                        onClick={() => handleClickOpenModalNotificacaoUsuarios()}
                        disabled={undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0 && props.notificacao.totalNotificacoesUsuarios == 0}
                        disabled={props.notificacaoFiltro.isLoading || undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0 && props.notificacao.totalNotificacoesUsuarios == 0}
                        startIcon={<PersonIcon />}
                        endIcon={props.notificacaoFiltro.isLoading && <CircularProgress size={24} />}
                      >
                        Ver lista de Usuários
                      </CustomButton>
                      <CustomButton
                        variant="outlined"
                        onClick={() => handleClickOpenModalNotificacaoTestes()}
                        disabled={undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0 || props.notificacao.id == 0}
                        disabled={props.notificacaoFiltro.isLoading || undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0}
                        startIcon={<SendIcon />}
                        endIcon={props.notificacaoFiltro.isLoading && <CircularProgress size={24} />}
                      >
                        Testar envio
                      </CustomButton>
                      <CustomButton
                        dark={true}
                        variant="contained"
                        onClick={() => handleSend()}
                        disabled={undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0}
                        disabled={props.notificacaoFiltro.isLoading || undefined === props.notificacaoFiltro.notificacaoFiltro || props.notificacaoFiltro.notificacaoFiltro.length === 0}
                        startIcon={<SendIcon />}
                        endIcon={props.notificacaoFiltro.isLoading && <CircularProgress size={24} />}
                      >
                        Enviar
                      </CustomButton>
                    </Grid>
                  </CardActions>
                </ValidatorForm>
              </Card>
            </Grid>
          </Grid>
          :
          null
        )}
      </Grid>
      <ModalNotificacaoFiltrosItens />
      <ModalNotificacaoUsuarios />
      <ModalNotificacaoTestes />
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.EMPRESA_EDIT_ANEXO}
          />
          :
          null
        )
      }
    </Page>
  )
}

NotificacaoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedNotificacaoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(NotificacaoEdit))
)
export { connectedNotificacaoEditPage as NotificacaoEdit }
