const initialState = {
  anchor: 'left',
  isLoading: false,
  empresa: [],
  open: false,
  id: 0,
  razaoSocial: '',
  nomeFantasia: '',
  cpf: '',
  cnpj: '',
  telefone: '',
  celular: '',
  email: '',
  endereco: '',
  idCidade: 0,
  idEstado: 0,
  inscricaoEstadual: '',
  informacoesCobranca: '',
  usuariosEmpresas: [],
  empresasOrgaos: [],
  cidade: [],
  totalRegistros: 0,
  atendeFimDeSemana: false,
  servicosUrgencia: false,
  fornecedor: false,
  autonomo: false,
  prestadorServico: false,
  projeto: false,
  cadastroLiberado: false,
  empresasAnexos: [],
  empresasTiposServicos: [],
}

export function empresa(state = initialState, action) {
  switch (action.type) {
    case 'EMPRESA_DETAIL':
      return {
        ...state,
        id: action.id,
        isLoading: false,
        razaoSocial: action.razaoSocial,
        nomeFantasia: action.nomeFantasia,
        cnpj: action.cnpj,
        cpf: action.cpf,
        telefone: action.telefone,
        celular: action.celular,
        email: action.email,
        endereco: action.endereco,
        idCidade: action.idCidade,
        idEstado: action.idEstado,
        informacoesCobranca: action.informacoesCobranca,
        inscricaoEstadual: action.inscricaoEstadual,
        usuariosEmpresas: action.usuariosEmpresas,
        empresasOrgaos: action.empresasOrgaos,
        fornecedor: action.fornecedor,
        cidade: action.cidade,
        prestadorServico: action.prestadorServico,
        autonomo: action.autonomo,
        projeto: action.projeto,
        cadastroLiberado: action.cadastroLiberado,
        atendeFimDeSemana: action.atendeFimDeSemana,
        servicosUrgencia: action.servicosUrgencia,
        empresasAnexos: action.empresasAnexos,
        empresasTiposServicos: action.empresasTiposServicos,
      }
    case 'EMPRESA_UPDATED':
      return state
    case 'EMPRESA_CLEAR':
      return initialState
    case 'EMPRESA_CLEAR_ALL':
      return {
        ...state,
        empresa: []
      }
    case 'EMPRESA_CLEAR_USUARIOS_EMPRESAS':
      return {
        ...state,
        usuariosEmpresas: []
      }
    case 'EMPRESA_CLEAR_TIPOS_SERVICOS':
      return {
        ...state,
        empresasTiposServicos: []
      }
    case 'FETCHED_ALL_EMPRESA':
      return {
        ...state,
        empresa: action.empresa,
        totalRegistros: action.totalRegistros
      }
    case 'EMPRESA_HANDLE_ON_CHANGE':
      if ((action.props === 'prestadorServico' || action.props === 'fornecedor') && action.value) {
        return {
          ...state,
          [action.props]: true,
          autonomo: false,
          projeto: false,
          empresasTiposServicos: []
        }
      } else if (action.props === 'autonomo' && action.value) {
        return {
          ...state,
          autonomo: true,
          fornecedor: false,
          projeto: false,
          prestadorServico: false,
          empresasTiposServicos: []
        }
      } else if (action.props === 'projeto' && action.value) {
        return {
          ...state,
          autonomo: false,
          fornecedor: false,
          projeto: true,
          prestadorServico: false,
          empresasTiposServicos: []
        }
      } else {
        return {
          ...state,
          [action.props]: action.value
        }
      }
    case 'EMPRESA_ISLOADING':
      return {
        ...state,
        isLoading: true
      }
    case 'EMPRESA_NOTISLOADING':
      return {
        ...state,
        isLoading: false
      }
    default:
      return state
  }
}
