import React, { useEffect, useMemo, useState } from 'react'
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import AttachFileIcon from '@mui/icons-material/AttachFile';
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import { withStyles } from '@mui/styles'
import Typography from '@mui/material/Typography'
import ObraSummaryCard from '../Obras/obraSummaryCard.component'
import {
  Autocomplete,
  Button,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  IconButton,
  Paper,
  Switch,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TextField,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator
} from 'react-material-ui-form-validator'
import {
  authActions,
  empresaAction,
  obraOrcamentoAction,
  obraOrcamentoItemAction,
  parametroAction,
} from '../../_actions'
import { withRouter } from 'react-router-dom'
import { history, stringUtils } from '../../utils'
import { Page } from 'components'
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { toast } from 'react-toastify'
import CustomButton from '../../components/CustomButton';
import { ObraOrcamentoAnexoEmpresaModal } from './obraOrcamentoAnexoEmpresaModal.component'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS, STATUS_SERVICO } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const CustomGrid = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left'
  },
}));

const CustomButton2 = styled(Button)(() => ({
  color: '#fff',
  backgroundColor: '#015192',
  borderColor: '#015192',
  borderRadius: 4,
  padding: 9,
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: '#000'
  }
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    marginTop: -10,
    marginBottom: -8,
    paddingTop: -10,
    paddingBottom: -8,
    paddingRight: 3,
    marginRight: 3,
    paddingLeft: 3,
    marginLeft: 3
  },
}));

const ObraOrcamentoEdit = (props) => {

  const dispatch = useDispatch()
  const [modalConfirm, setModalConfirm] = useState(false);

  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const formRef = React.createRef(null);
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const location = useLocation();
  const idObra = location.state;

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  var sum = 0;

  const [cont, setCont] = useState(0);

  const [valueEmpresa, setValueEmpresa] = React.useState(null);
  const [inputValueEmpresa, setInputValueEmpresa] = React.useState('');
  const [optionsEmpresa, setOptionsEmpresa] = React.useState([]);

  const [open, setOpen] = React.useState(false)

  const [valorOrcamento, setValorOrcamento] = useState(0);

  const { obraEtapa } = useSelector(
    (state) => state.obraEtapa)

  const { obraOrcamento } = useSelector(
    (state) => state.obraOrcamento)

  const { empresa } = useSelector(
    (state) => state.empresa
  )

  const handleOpenModal = () => {
    toggle();
  }

  useEffect(() => {
    if (authActions.isGestor()) {
      dispatch(empresaAction.getEmpresaSelect())

      setOptionsEmpresa(empresa.map(u => ({ id: u.value, nome: u.text })));
    }
  }, [empresa])

  useEffect(() => {

    dispatch(obraOrcamentoAction.clear())

    dispatch(parametroAction.getTermoAceiteEmpresas())

    if (params.id) {
      dispatch(obraOrcamentoAction.getObraOrcamentoById(params.id))
    } else {
      dispatch(obraOrcamentoAction.clear())
      setValorOrcamento(0);
      setCont(0);
      sum = 0;

      let changeLatEvent = {
        type: 'text',
        target: {
          value: moment().format('yyyy-MM-DD')
        }
      };
      dispatch(obraOrcamentoAction.onChangeProps('data', changeLatEvent));

      if (props.obra.dataTermino && props.obra.dataInicio) {
        changeLatEvent = {
          type: 'text',
          target: {
            value: moment(props.obra.dataTermino).diff(moment(props.obra.dataInicio), 'days')
          }
        };
        dispatch(obraOrcamentoAction.onChangeProps('diasPrazo', changeLatEvent));

        changeLatEvent = {
          type: 'text',
          target: {
            value: props.obra.dataTermino
          }
        };
        dispatch(obraOrcamentoAction.onChangeProps('prazo', changeLatEvent));
      }
    }
  }, []);

  const handleChange = (prop) => (event) => {
    dispatch(obraOrcamentoAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    if (idObra && idObra != 0 && (params.id == undefined || params.id == 0)) {
      setValorOrcamento(0);
      setCont(0);
      sum = 0;
      dispatch(obraOrcamentoAction.onChangeProps('obrasOrcamentosItens', []));
    }

  }, [])

  useEffect(() => {
    if (props.obraOrcamento.idEmpresa != 0 && params.id != undefined) {
      setValueEmpresa(props.obraOrcamento.empresa)
    }

  }, [props.obraOrcamento.idEmpresa])

  useEffect(() => {
    if (valueEmpresa) {
      handleChangeAutoCompleteEmpresa(valueEmpresa)
    }
  }, [valueEmpresa])

  const handleChangeAutoCompleteEmpresa = (obj) => {
    if (obj != null) {
      let selected = empresa.filter(
        (item) => item.value == obj.id
      )

      dispatch(
        empresaAction.changeDetailsEmpresa(
          selected[0]
        )
      )
    } else {

    }
    setOpen(false)
  }

  function getValorTotalOrcado(idObraEtapaItem) {
    const obrasOrcamentosItens = props.obraOrcamento?.obrasOrcamentosItens;
    if (idObraEtapaItem != 0 && obrasOrcamentosItens?.length) {
      const item = obrasOrcamentosItens.find(n => n.idObraEtapaItem == idObraEtapaItem);
      return item ? (item.valor).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '';
    }
  }

  function getValorOrcado(idObraEtapaItem) {
    const obrasOrcamentosItens = props.obraOrcamento?.obrasOrcamentosItens;
    if (idObraEtapaItem != 0 && obrasOrcamentosItens?.length) {
      const valor = obrasOrcamentosItens.find(n => n.idObraEtapaItem == idObraEtapaItem);
      return valor ? valor.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '';
    }
  }

  const handleChangeValor = (event, n) => {
    const obrasOrcamentosItens = props.obraOrcamento?.obrasOrcamentosItens;

    if (obrasOrcamentosItens?.length) {
      const index = obrasOrcamentosItens.findIndex(x => x.idObraEtapaItem == n.id);
      if (index >= 0) {
        const novosValores = obrasOrcamentosItens.map(x =>
          x.idObraEtapaItem == n.id ? { ...x, valor: parseFloat(event.target.value), valorTotal: parseFloat(event.target.value) } : x
        );
        dispatch(obraOrcamentoAction.onChangeProps('obrasOrcamentosItens', novosValores));
        setCont(cont + 1);
      } else {
        const payload = {
          id: 0,
          idObraOrcamento: idObra,
          idObraEtapaItem: n.id,
          valor: parseFloat(event.target.value),
          valorTotal: parseFloat(event.target.value)
        };
        obrasOrcamentosItens.push(payload);
        dispatch(obraOrcamentoAction.onChangeProps('obrasOrcamentosItens', obrasOrcamentosItens));
        setCont(cont + 1);
      }
    } else {
      const payload = {
        id: 0,
        idObraOrcamento: idObra,
        idObraEtapaItem: n.id,
        valor: parseFloat(event.target.value),
        valorTotal: parseFloat(event.target.value)
      }

      obrasOrcamentosItens.push(payload);
      dispatch(obraOrcamentoAction.onChangeProps('obrasOrcamentosItens', obrasOrcamentosItens))
      setCont(cont + 1);
    }

  };

  useEffect(() => {
    //calcula o valor total do orçamento, somando o valor total de cada item
    if (cont > 0) {
      if (props.obraOrcamento.obrasOrcamentosItens && props.obraOrcamento.obrasOrcamentosItens.length) {
        sum = 0;
        sum = props.obraOrcamento.obrasOrcamentosItens.reduce(function (accumulator, item) {
          return accumulator + item.valorTotal
        }, 0);
        setValorOrcamento(sum);
      }
    }
  }, [cont])

  useEffect(() => {
    if (props.obraOrcamento.data && props.obraOrcamento.diasPrazo) {
      const day = moment(props.obraOrcamento.data).add(props.obraOrcamento.diasPrazo, 'days');
      props.obraOrcamento.prazo = moment(day).format("yyyy-MM-DD");
    }
  }, [props.obraOrcamento.diasPrazo, props.obraOrcamento.data])

  const handleModalConfirm = (event) => {

    if (!props.obraOrcamento.diasPrazo) {
      toast.error('Informe um prazo para o orçamento')
      return;
    }

    if (!valorOrcamento) {
      toast.error('O valor do orçamento não poder ficar em branco')
      return;
    }

    setModalConfirm(true);
  }

  const handleSubmit = (event) => {

    let payload = {
      id: params.id,
      data: moment(props.obraOrcamento.data).toISOString(),
      prazo: props.obraOrcamento.prazo,
      diasPrazo: props.obraOrcamento.diasPrazo,
      valor: valorOrcamento,
      observacoes: props.obraOrcamento.observacoes,
      obrasOrcamentosItens: props.obraOrcamento.obrasOrcamentosItens,
      idObra: idObra,
      idEmpresa: authActions.isEmpresa() ? props.authentication.user.usuariosEmpresas ? props.authentication.user.usuariosEmpresas[0].idEmpresa : valueEmpresa.id : valueEmpresa.id,
    }

    if (params.id) {
      dispatch(obraOrcamentoAction.editObraOrcamentoInfo(params.id, payload, true))
    } else {
      dispatch(obraOrcamentoAction.createObraOrcamento(payload))
    }
  };

  function getValor(idObraEtapaItem, quantidadeItem) {
    const item = props.obraOrcamento?.obrasOrcamentosItens?.find(p => p.idObraEtapaItem == idObraEtapaItem);
    if (item) {
      if (item.valor) {
        //return quantidadeItem ? item.valor : item.valor;
        return item.valor;
      } else {
        return null;
      }
    }
    return null;
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{'Novo Orçamento'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{'Visualizar Orçamento'}</b>
      </Typography>
    )
  }

  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? 'Gerência de Orçamento - Editar Orçamento'
          : 'Gerência de Orçamento - Novo Orçamento'
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Grid item md={12} xs={12}>
            <Card>
              <ValidatorForm
                className={classes.form}
                ref={formRef}
                id="formObraOrcamento"
                onSubmit={(event) => handleModalConfirm(event)}
              >
                <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <CardHeader
                    subheader="Cadastro de Orçamento"
                    title={<SegHeader />}
                  />
                  <Grid item lg={1} md={1} xl={1} xs={12}>
                    <IconButton
                      aria-label="Ajuda"
                      component="a"
                      onClick={() =>
                        handleOpenModalHelp()
                      }
                      size="small"
                    >
                      <Tooltip title={'Ajuda'}>
                        <Tooltip>
                          <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  </Grid>
                </Grid>
                <Divider />
                <CardContent lg={12} md={12} xl={12} xs={12}>
                  {idObra != undefined ?
                    <ObraSummaryCard idObra={idObra} isOrcamento={true} latitude={props.obra.latitude} longitude={props.obra.longitude} />
                    :
                    <ObraSummaryCard idObra={params.id} isOrcamento={true} latitude={props.obra.latitude} longitude={props.obra.longitude} />
                  }
                  <Grid container spacing={2}>
                    <Grid item lg={12} md={12} xl={12} xs={12}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={6} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <div>
                                  <strong>{params.id ? 'Itens orçados da obra' : 'Itens a serem orçados'}</strong>
                                </div>
                                <div>
                                  <CustomButton2 onClick={() => handleOpenModal()}>
                                    Ver documentos da obra&nbsp;<AttachFileIcon />
                                  </CustomButton2>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(idObra && idObra != 0 && (params.id == 0 || params.id == undefined)) && props.obra.obrasEtapas != null ?
                              props.obra.obrasEtapas.map((n) => {
                                return (
                                  <>
                                    <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}
                                      key={n.id}
                                    >
                                      <TableCell colSpan={6} component="th" scope="row" align="center">
                                        <strong>{n.titulo}</strong>
                                      </TableCell>
                                    </TableRow>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableHead>
                                        <TableRow>
                                          <TableCell><strong>Código</strong></TableCell>
                                          <TableCell><strong>Descritivo</strong></TableCell>
                                          <TableCell><strong>Valor Ref. Un.</strong></TableCell>
                                          <TableCell align="right"><strong>Quantidade</strong></TableCell>
                                          <TableCell><strong>Valor Ref.</strong></TableCell>
                                          <TableCell><strong>Valor Ref. BDI</strong></TableCell>
                                          <TableCell align="right"><strong>Valor da Proposta</strong></TableCell>
                                          <TableCell align="right"><strong>Valor Total Item</strong></TableCell>
                                        </TableRow>
                                      </TableHead>
                                      <TableBody>
                                        {n.obrasEtapasItens?.map((n1, index) => (
                                          <TableRow hover key={index}>
                                            <TableCell>{n1.codigo}</TableCell>
                                            <TableCell>{n1.descritivo}</TableCell>
                                            <TableCell>{n1.valorReferencia ? 'R$' + n1.valorReferencia.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '-'}</TableCell>
                                            <TableCell align="right">{n1.quantidade} {n1.unidade.descricao}</TableCell>
                                            <TableCell>{n1.valorReferencia ? 'R$' + (n1.valorReferencia * n1.quantidade).toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '-'}</TableCell>
                                            <TableCell>{n1.valorReferenciaComBdi ? 'R$' + n1.valorReferenciaComBdi.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '-'}</TableCell>
                                            <TableCell align="right">
                                              <Input
                                                id="valorItem"
                                                autoComplete="off"
                                                className="form-control"
                                                type="number"
                                                readOnly={params.id}
                                                style={{ width: 125 }}
                                                value={props.obraOrcamentoItem.valor ? props.obraOrcamentoItem.valor : getValor(n1.id) || ''}
                                                startAdornment={<Typography position="left" style={{ fontSize: 12 }}>R$&nbsp;</Typography>}
                                                onChange={(event) => handleChangeValor(event, n1)}
                                                inputProps={{
                                                  min: 0,
                                                  step: "0.01",
                                                }}
                                              />
                                            </TableCell>
                                            <TableCell style={{ minWidth: 125 }} align="right">R$&nbsp;{(props.obraOrcamentoItem.valor ? props.obraOrcamentoItem.valor : getValor(n1.id, n1.quantidade) ? getValor(n1.id, n1.quantidade) : 0).toLocaleString('pt-br', { minimumFractionDigits: 2 })}</TableCell>
                                          </TableRow>
                                        ))}
                                      </TableBody>
                                    </Table>

                                  </>
                                )
                              })
                              : params.id == 0 &&
                              <TableRow
                                key={obraEtapa.id}
                              >
                                <TableCell colSpan={6} component="th" scope="row" align="center">
                                  Esta obra não possui etapas para exibir
                                </TableCell>
                              </TableRow>
                            }
                            {params.id != 0 && params.id != undefined && props.obraOrcamento?.obra?.obrasEtapas ?
                              props.obraOrcamento?.obra?.obrasEtapas.map((n) => {
                                return (
                                  <>
                                    <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}
                                      key={n.id}
                                    >
                                      <TableCell colSpan={6} component="th" scope="row" align="center">
                                        <strong>{n.titulo}</strong>
                                      </TableCell>
                                    </TableRow>
                                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                      <TableRow>
                                        <TableCell><strong>Código</strong></TableCell>
                                        <TableCell><strong>Descritivo</strong></TableCell>
                                        <TableCell><strong>Valor Ref. Un.</strong></TableCell>
                                        <TableCell align="right"><strong>Quantidade</strong></TableCell>
                                        <TableCell><strong>Valor Ref. + BDI</strong></TableCell>
                                        <TableCell align="right"><strong>Valor da Proposta</strong></TableCell>
                                        <TableCell align="right"><strong>Valor Total Item</strong></TableCell>
                                      </TableRow>
                                      <TableBody>
                                        {n.obrasEtapasItens ? n.obrasEtapasItens.map((oe) => {
                                          return (
                                            <TableRow hover>
                                              <TableCell>{oe.codigo ? oe.codigo : ''}</TableCell>
                                              <TableCell>{oe.descritivo}</TableCell>
                                              <TableCell>R$ {oe.valorReferencia?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</TableCell>
                                              <TableCell align="right">{oe.quantidade} {oe.unidade?.descricao}</TableCell>
                                              <TableCell>R$ {oe.valorReferenciaComBdi?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</TableCell>
                                              <TableCell align="right">R$ {getValorOrcado(oe.id)}</TableCell>
                                              <TableCell align="right">R$ {getValorTotalOrcado(oe.id)}</TableCell>
                                            </TableRow>
                                          )
                                        })
                                          :
                                          <TableCell colSpan={6} component="th" scope="row" align="center">
                                            Esta obra não possui etapas para exibir
                                          </TableCell>
                                        }
                                      </TableBody>
                                    </Table>
                                  </>
                                )
                              })
                              : null
                            }
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                {idObra && idObra != 0 && (params.id == undefined || params.id == 0) ?
                  <CardContent lg={12} md={12} xl={12} xs={12}>
                    <Grid container spacing={2}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={6} align="center"><strong>Elaborar orçamento</strong></TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          type="date"
                          disabled
                          id="data"
                          label="Data do Orçamento *"
                          margin="normal"
                          value={props.obraOrcamento.data ? moment(props.obraOrcamento.data).format("yyyy-MM-DD") : ''}
                          variant="outlined"
                          InputLabelProps={{
                            shrink: true
                          }}
                        />
                      </Grid>
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          type="number"
                          id="diasPrazo"
                          label="Prazo de Execução (dias) *"
                          onChange={handleChange('diasPrazo')}
                          margin="normal"
                          value={props.obraOrcamento.diasPrazo}
                          variant="outlined"
                          validators={['required']}
                          InputLabelProps={{
                            shrink: true
                          }}
                          inputProps={{
                            min: 0
                          }}
                          errorMessages={['Campo obrigatório!']}
                        />
                      </Grid>
                      {/*        se não for empresa, pede para selecionar*/}
                      {!authActions.isEmpresa() &&
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <Autocomplete
                            value={valueEmpresa}
                            onChange={(event, newValue) => {
                              setValueEmpresa(newValue);
                            }}
                            style={{ marginTop: 16 }}
                            inputValue={inputValueEmpresa}
                            onInputChange={(event, newInputValue) => {
                              setInputValueEmpresa(newInputValue);
                            }}
                            id="controllable-states-demo"
                            options={optionsEmpresa}
                            isOptionEqualToValue={(option, value) => option.id == value.id}
                            margin="normal"
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            defaultValue={valueEmpresa}
                            getOptionLabel={option => option.nome || ''}
                            renderInput={(params) => <TextField {...params} label="Selecione uma empresa..." fullWidth required />}
                            fullWidth
                          />
                        </Grid>
                      }
                      <Grid item lg={2} md={2} xl={2} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          id="valor"
                          label="Valor do Orçamento"
                          margin="normal"
                          InputProps={{
                            startAdornment: <Typography position="left">R$&nbsp;</Typography>
                          }}
                          value={valorOrcamento ? valorOrcamento.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0 || 0}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <TextValidator
                          className={classes.textField}
                          fullWidth
                          readOnly
                          InputLabelProps={{
                            shrink: true
                          }}
                          id="observacoes"
                          label="Observações"
                          onChange={handleChange('observacoes')}
                          margin="normal"
                          onInput={stringUtils.toInputUppercase}
                          value={props.obraOrcamento.observacoes || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                  : params.id != 0 && params.id != undefined ?
                    <CardContent lg={12} md={12} xl={12} xs={12}>
                      <Grid container spacing={2}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell colSpan={6} align="center"><strong>Informações do Orçamento</strong></TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="date"
                            id="data"
                            label="Data"
                            margin="normal"
                            value={props.obraOrcamento.data ? moment(props.obraOrcamento.data).format("yyyy-MM-DD") : ''}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={1} md={1} xl={1} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            type="number"
                            id="diasPrazo"
                            label="Dias Prazo"
                            margin="normal"
                            value={props.obraOrcamento.diasPrazo}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={3} md={3} xl={3} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="empresa"
                            label="Empresa"
                            margin="normal"
                            value={props.obraOrcamento.empresa?.razaoSocial?.toUpperCase()}
                            variant="outlined"
                            InputLabelProps={{
                              shrink: true
                            }}
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={2} md={2} xl={2} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            id="valor"
                            label="Valor do Orçamento"
                            margin="normal"
                            InputProps={{
                              startAdornment: <Typography position="left">R$&nbsp;</Typography>
                            }}
                            value={props.obraOrcamento.valor ? props.obraOrcamento.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}
                            variant="outlined"
                            readOnly
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            readOnly
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="observacoes"
                            label="Observações"
                            margin="normal"
                            value={props.obraOrcamento?.observacoes?.toUpperCase() || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    : null
                }
                <CardActions>
                  {(params.id == undefined || params.id == 0) ?
                    <>
                      <Grid container spacing={2}>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Typography style={{ fontSize: 10, paddingLeft: 6 }}>
                            * Campos obrigatórios
                          </Typography>
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Typography style={{ fontSize: 10, paddingLeft: 6 }}>
                            Atenção: Ao enviar o orçamento você se propõe e considerar o valor orçado até a data de previsão de início da obra
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        display: 'flex',
                        alignItems: 'right',
                        flexDirection: 'row',
                        textAlgin: 'right',
                        justifyContent: 'right'
                      }}>
                        <CustomButton
                          variant="outlined"
                          onClick={params.id == undefined ? () => history.push('/obrasAguardandoOrcamento') : () => history.push('/obrasOrcamentos')}
                        >
                          Cancelar
                        </CustomButton>
                        <CustomButton
                          type="submit"
                          variant="contained"
                          dark={true}
                          disabled={props.obraOrcamento.isLoading || obraOrcamento.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS}
                          startIcon={<SaveIcon />}
                          endIcon={props.obraOrcamento.isLoading && <CircularProgress size={24} />}
                        >
                          {params.id ? (props.obraOrcamento.isLoading ? 'Atualizando Orçamento...' : 'Atualizar') : (props.obraOrcamento.isLoading ? 'Salvando Orçamento...' : 'Salvar')}
                        </CustomButton>
                      </Grid>
                    </>
                    :
                    <Button
                      variant="outlined"
                      style={{ color: '#000', borderColor: '#000' }}
                      onClick={() => history.goBack()}
                    >
                      Voltar para tela anterior
                    </Button>
                  }
                </CardActions>
              </ValidatorForm>
            </Card >
          </Grid >
        </Grid >
      </Grid >
      {
        modalConfirm ?
          <Dialog
            open={modalConfirm}
            fullWidth
            style={{ padding: 0, margin: 0 }}
            maxWidth='xs'
            onClose={() => setModalConfirm(false)}
          >
            <DialogTitle>
              <Typography style={{ fontSize: "16px", fontWeight: 'bold' }}>
                CONFIRMAÇÃO DE ENVIO DE ORÇAMENTO
              </Typography>
              <Button
                variant="text"
                style={{ position: 'absolute', top: 0, right: 0, color: '#000', minWidth: 'unset' }}
                onClick={() => setModalConfirm(false)}>
                <CloseIcon />
              </Button>
            </DialogTitle>
            <DialogContent style={{ padding: 0, margin: 0 }}>
              <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
                <Grid item display="flex" flexDirection="column" justifyContent="center">
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} >Ao enviar o orçamento você se compromete a começar a obra no prazo estipulado.</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Resumo do seu orçamento:</strong></CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Data: </strong>{moment(props.obraOrcamento.data).format('DD/MM/yyyy')}</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12 }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Valor Total: </strong>{valorOrcamento ? valorOrcamento.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : 0}</CustomTypography>
                    </CustomGrid>
                  </Grid>
                  <Grid container spacing={2} style={{ paddingLeft: 12, paddingRight: 12, }}>
                    <CustomGrid item xl={12} lg={12} md={12} xs={12} align="left">
                      <CustomTypography style={{ fontSize: 13 }} ><strong>Prazo de Entrega: </strong>{moment(props.obraOrcamento.prazo).format('DD/MM/yyyy')}</CustomTypography>
                    </CustomGrid>
                  </Grid>

                  <Button
                    onClick={(event) => handleSubmit(event)}
                    variant="contained"
                    style={{ backgroundColor: '#000', color: '#FFF', marginTop: 16 }}
                    disabled={props.obraOrcamento.isLoading || obraOrcamento.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS}
                    startIcon={<SaveIcon />}
                    endIcon={props.obraOrcamento.isLoading && <CircularProgress size={24} />}
                  >
                    {params.id ? (props.obraOrcamento.isLoading ? 'Atualizando Orçamento...' : 'Atualizar') : (props.obraOrcamento.isLoading ? 'Salvando Orçamento...' : 'Enviar Orçamento')}
                  </Button>
                </Grid>
              </Card>
            </DialogContent>
          </Dialog >
          : null
      }
      {
        (modal ?
          <ObraOrcamentoAnexoEmpresaModal toggle={toggle} modal={modal} props={props} idObra={idObra} />
          :
          null
        )
      }
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.CADASTRO_ORCAMENTO}
          />
          :
          null
        )
      }
    </Page >
  )
}

ObraOrcamentoEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraOrcamentoEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraOrcamentoEdit))
)
export { connectedObraOrcamentoEditPage as ObraOrcamentoEdit }
