import React, { useLayoutEffect, useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import {
  IconButton,
  Typography,
  Grid,
  Tooltip
} from '@mui/material'
import { withStyles } from '@mui/styles'
import messageTime from 'utils/stringTimeHello'
import { Page } from 'components'
import { withRouter } from 'react-router-dom'
import { authActions, dashboardAction } from '../../_actions'
import CardAnexosPendentes from '../Dashboard/components/CardAnexosPendentes'
import CardObra from '../Dashboard/components/CardObra'
import { history } from './../../utils'
import CardObrasAindaNaoReceberamOrcamento from '../Dashboard/components/CardObrasAindaNaoReceberamOrcamento'
import CardHorasRecebimentoOrcamentos from '../Dashboard/components/CardHorasRecebimentoOrcamentos'
import CardInfosValues from './components/CardInfosValues'
import background from "../../img/background.png";
import { CardMedicoesPendentes, CardObrasRecebendoOrcamentos, CardOrcamentosEnviados } from './components'
import CardListaObraInteresse from './components/CardListaObraInteresse'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { LocalAtmOutlined, PriceCheck } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import { MANUAIS_CATEGORIAS } from '../../utils'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundRepeat: 'repeat'
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textAlign: 'center'
  },
  gridCards: {
    //alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(1)
    }
  },
});

const Dashboard = (props) => {

  const { className, classes, ...rest } = props
  const { match: { params } } = props;
  const dispatch = useDispatch();
  const [pendentes, setPendentes] = useState(true);

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const user = useSelector((state) => state.authentication.user)

  useLayoutEffect(() => {
    dispatch(authActions.refresh())

  }, [])

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {
    let filterModel = {
      IdOrgao: idOrgao
    }
    dispatch(dashboardAction.getDashboard(filterModel));

  }, [idOrgao])

  useEffect(() => {
    let filterModel = {
      IdOrgao: idOrgao
    }
    dispatch(dashboardAction.getDashboard(filterModel));

  }, [])

  const handleClickOpenObrasPendentes = () => {
    history.push(`/obras`, pendentes)
  }

  return (
    <Page
      className={classes.root}
      title='Raiar - Dashboard'
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Grid alignItems="center" container justifyContent="center" spacing={3}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography component="h1" gutterBottom variant="h5">
                {messageTime()}, {user ? user.nome : ''}
              </Typography>
              {authActions.isGestor() ?
                <Grid item lg={1} sm={1} xl={1} xs={12}>
                  <IconButton
                    aria-label="Ajuda"
                    component="a"
                    onClick={() =>
                      handleOpenModalHelp()
                    }
                    size="small"
                  >
                    <Tooltip title={'Ajuda'}>
                      <Tooltip>
                        <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                      </Tooltip>
                    </Tooltip>
                  </IconButton>
                </Grid>
                : null}
            </div>
            <br></br>
            <Typography component="h2" gutterBottom variant="h6">
              {'Seja bem-vindo'}
            </Typography>
          </Grid>
          <Grid item lg={6} sm={6} xl={6} xs={12} align="right">
            <Grid item lg={12} sm={12} xl={12} xs={12}>
              <CardHorasRecebimentoOrcamentos />
            </Grid>
          </Grid>
          <Grid container className={classes.gridCards} spacing={2}>
            <Grid container lg={12} sm={12} xl={12} xs={12} spacing={1} justifyContent="start">
              {(!authActions.isFiscal() && !authActions.isEmpresa()) &&
                <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                  <CardObra onClick={() => handleClickOpenObrasPendentes()} pendentes={pendentes} style={{ cursor: 'pointer' }} />
                </Grid>
              }
              {(authActions.isGestor() || authActions.isEmpresa()) &&
                <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                  {authActions.isGestor() ?
                    <CardAnexosPendentes
                      onClick={() => history.push(`/empresasAnexos`)}
                      style={{ cursor: 'pointer' }}
                    />
                    :
                    <CardOrcamentosEnviados
                      onClick={() => history.push(`/obrasOrcamentos`)}
                      style={{ cursor: 'pointer' }}
                    />
                  }
                </Grid>
              }
              <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                {(authActions.isGestor() || authActions.isFiscal()) ?
                  <CardMedicoesPendentes
                    onClick={() => history.push(`/obrasCronogramas`)}
                    style={{ cursor: 'pointer' }} />
                  :
                  <CardObrasRecebendoOrcamentos
                    onClick={() => history.push(`/obrasAguardandoOrcamento`)}
                    style={{ cursor: 'pointer' }}
                  />
                }
              </Grid>
              {authActions.isGestor() &&
                <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                  <CardObrasAindaNaoReceberamOrcamento
                    onClick={() => history.push(`/obrasAguardandoOrcamento`)}
                    style={{ cursor: 'pointer' }} />
                </Grid>
              }
              {(authActions.isGestor() || authActions.isPrefeitura()) &&
                <>
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardInfosValues
                      indicator="valorTotalEmpenhosEmAberto"
                      text="Valor de empenhos em aberto"
                      icon={<LocalAtmOutlined />}
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardInfosValues
                      indicator="valorTotalEmpenhosPagos"
                      text="Valor de empenhos pagos"
                      icon={<PriceCheck />}
                    />
                  </Grid>
                  <Grid item lg={3} sm={3} xl={3} md={6} xs={12}>
                    <CardInfosValues
                      indicator="valorTotalEmpenhos"
                      text="Valor total de empenhos"
                      icon={<MonetizationOnIcon />}
                    />
                  </Grid>
                </>
              }
              {authActions.isEmpresa() &&
                <Grid container className={classes.gridCards} spacing={3}>
                  <Grid item lg={12} sm={12} xl={12} md={12} xs={12}>
                    <CardListaObraInteresse />
                  </Grid>
                </Grid>
              }
            </Grid>
            {/*<Grid container className={classes.gridCards} spacing={3}>*/}
            {/*  <Grid item xl={6} lg={6} md={6} xs={12}>*/}
            {/*    <GraficoObrasStatusChartCard />*/}
            {/*  </Grid>*/}
            {/*  <Grid item xl={6} lg={6} md={6} xs={12}>*/}
            {/*    <GraficoObrasPorMesChartCard />*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
          </Grid>
        </Grid>
        {
          (modalHelp ?
            <HelpModal
              toggle={toggleModalHelp}
              modal={modalHelp}
              props={props}
              tela={MANUAIS_CATEGORIAS.DASHBOARD}
            />
            :
            null
          )
        }
      </div>
    </Page >
  )
}
Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}
const connectedDashboardPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(Dashboard))
)
export { connectedDashboardPage as Dashboard }
