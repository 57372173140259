import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { GoogleApiWrapper, InfoWindow, Map, Marker } from 'google-maps-react';
import moment from 'moment/moment';
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import logo from "../../img/raiar.png";
import { editObrasDetails } from '../../_actions/obra.actions';

const pinRed = require('../../img/pinRed.png');
const pinBlue = require('../../img/pinBlue.png');

const MapPublic = (props) => {
  const dispatch = useDispatch();
  const mapRef = useRef(null);

  const [mapProps, setMapProps] = useState({
    region: { lat: -30.02366731707499, lng: -51.22179761534211 },
    zoom: 15,
    marker: null,
    obra: null,
    showInfoWindow: false
  });

  // Atualiza o centro com base na coordenada da primeira obra do órgão selecionado
  useEffect(() => {
    if (props.obras?.length > 0) {
      // Encontre uma obra do órgão selecionado
      const obraSelecionada = props.obras.find(obra => obra.latitude && obra.longitude);

      if (obraSelecionada) {
        setMapProps((prev) => ({
          ...prev,
          region: { lat: obraSelecionada.latitude, lng: obraSelecionada.longitude },
          zoom: 15
        }));

        if (mapRef.current && props.google) {
          const mapInstance = mapRef.current.map;
          mapInstance.panTo({ lat: obraSelecionada.latitude, lng: obraSelecionada.longitude });
          mapInstance.setZoom(15);
        }
      }
    }
  }, [props.obras, props.google]);

  const onMarkerClick = (propsMarker, marker) => {
    setMapProps({
      region: propsMarker.position,
      marker: marker,
      obra: propsMarker.obra,
      showInfoWindow: true,
      zoom: 15
    });

    dispatch(editObrasDetails(propsMarker.obra));
  };

  const onCloseInfoWindow = () => {
    setMapProps((prev) => ({
      ...prev,
      showInfoWindow: false
    }));
  };

  return (
    <Map
      google={props.google}
      zoom={mapProps.zoom}
      center={mapProps.region}
      initialCenter={mapProps.region}
      centerAroundCurrentLocation
      ref={mapRef}
    >
      {props?.obras.length > 0 && props.obras.map((obra) => {
        if (obra.latitude && obra.longitude) {
          return (
            <Marker
              name={obra.titulo}
              title={obra.titulo}
              obra={obra}
              key={obra.id}
              position={{ lat: obra.latitude, lng: obra.longitude }}
              onClick={onMarkerClick}
              icon={props.selectedId === obra.id ? pinBlue : pinRed}
            />
          );
        }
      })}
      {mapProps.showInfoWindow && (
        <InfoWindow
          marker={mapProps.marker}
          position={mapProps.region}
          visible={mapProps.showInfoWindow}
          onClose={onCloseInfoWindow}
        >
          <Box display="flex" flexDirection="row" alignItems="center" justifyContent="space-between" maxWidth="400px" columnGap={2}>
            <img src={mapProps.obra?.caminhoImagem || logo} width={100} height={100} style={{ borderRadius: '8px', objectFit: mapProps.obra?.caminhoImagem ? 'cover' : 'contain' }} />
            <Box display="flex" flexDirection="column">
              <Typography variant="h6" style={{ fontSize: '1rem', lineHeight: '1rem', marginBottom: '4px' }}>{mapProps.obra.titulo}</Typography>
              <Typography variant="p" mb={1}>{mapProps.obra.descritivo}</Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Typography variant="p">Ínicio: {moment(mapProps.obra.dataInicio).format('DD/MM/YYYY')}</Typography>
                {mapProps.obra.dataTermino ? <Typography variant="p">Término: {moment(mapProps.obra.dataTermino).format('DD/MM/YYYY')}</Typography> : null}
              </Box>
              {mapProps.obra?.valorReferencia ? <Typography variant="p" mt={1} style={{ fontWeight: 'bold' }}>Valor de referência: R${mapProps.obra?.valorReferencia?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}</Typography> : null}
            </Box>
          </Box>
        </InfoWindow>
      )}
    </Map>
  );
};

export default GoogleApiWrapper((props) => ({
  apiKey: 'AIzaSyC1QwWDv0BApsU0gjwIqEgmns_8RX2s-AU',
}))(MapPublic);

