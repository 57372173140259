import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { authActions, obraAction } from '../../_actions'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import {
  Grid,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Input,
  Paper,
  Radio,
  RadioGroup,
  Tooltip,
  Skeleton,
  TablePagination,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import EditIcon from '@mui/icons-material/Edit'
import { withStyles } from '@mui/styles'
import { withRouter } from 'react-router-dom'
import CircleIcon from '@mui/icons-material/Circle';
import TimeIcon from '@mui/icons-material/AccessTime';
import CheckIcon from '@mui/icons-material/CheckCircle';
import { history, getNomeUltimoEvento, getCor, MANUAIS_CATEGORIAS, STATUS_SERVICO } from '../../utils'
import TitleContent from '../TitleContent'
import { AccessControl } from '../../components/Utils/AccessControl'
import AccessDenied from '../Errors/AccessDenied'
import { Page } from 'components'
import { useDebounce } from 'use-debounce';
import { ObraMotivoModal } from '../../views/Obras'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(6, 6, 6, 6)
  },
  textField: {
    fontSize: 8
  }
})

const DivStyled = styled('div')(() => ({
  marginLeft: 2,
  backgroundColor: '#B0BEC5',
  borderRadius: 8,
  padding: 3,
  color: '#212121',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginTop: 4
}));

const ObraPlanoTrabalho = (props) => {

  const dispatch = useDispatch()
  const { classes, className } = props
  const { obra } = props.obra
  const { totalRegistros } = props.obra
  const { isLoading } = props.obra
  const [idObra, setIdObra] = useState(0);

  const [status, setStatus] = useState('');
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');
  const [firstTimeAcessPage, setFirstTimeAcessPage] = useState(false);

  const location = useLocation();
  const pendentes = location.state;

  const [modalMotivo, setModalMotivo] = useState(false);
  const toggleModalMotivo = () => setModalMotivo(!modalMotivo);

  const handleOpenModalMotivo = (id) => {
    setIdObra(id)
    toggleModalMotivo();
  }

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {

    dispatch(obraAction.clear())

    if (pendentes) {
      setStatus('1');
      setFirstTimeAcessPage(true);

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        IdOrgao: (idOrgao && idOrgao != '' && idOrgao),
        Status: 1,
        IsPlanoTrabalho: true
      };

      dispatch(obraAction.getObra(filterModel, true));
    }

    setTimeout(() => {
      setFirstTimeAcessPage(false);
    }, 1000);

  }, [direction, dispatch, idOrgao, limit, order, page, pendentes, term])

  useEffect(() => {

    const fetchData = (page, limit, term) => {
      let filterModel = null;

      if (authActions.isGestor() || authActions.isPrefeitura()) {
        filterModel = {
          Page: page,
          Limit: limit,
          Term: term,
          Order: order,
          Direction: direction,
          IdOrgao: idOrgao ? idOrgao : 0,
          Status: pendentes ? 1 : firstTimeAcessPage ? '' : status,
          IsPlanoTrabalho: true
        };

      } else {
        filterModel = {
          Page: page,
          Limit: limit,
          Term: term,
          Order: order,
          Direction: direction,
          IsPlanoTrabalho: true
        };
      }

      dispatch(obraAction.getObra(filterModel, true));

    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction, idOrgao, firstTimeAcessPage, pendentes, status, dispatch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  const handleChangeRadio = prop => event => {
    setStatus(event.target.value);
  };

  return (
    <Page className={classes.root} title="Obras">
      <AccessControl
        rule={'obras.list'} //permissão necessária para acessar conteúdo
        yes={() => (
          <div>
            {obra && undefined !== obra && obra.length > 0 ? (
              <TitleContent
                rule={'obras.create'}
                length={obra.length}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_OBRA_PLANO_TRABALHO}
                subTitle={'GERÊNCIA DE OBRA'}
                title={'Lista de planos de trabalho/projetos técnicos'}
                href={'/obra?obra=false&planoTrabalho=true'}
              />
            ) : (
              <TitleContent
                rule={'obras.create'}
                length={0}
                subTitle={'GERÊNCIA DE OBRA'}
                title={'Lista de planos de trabalho/projetos técnicos'}
                href={'/obra?obra=false&planoTrabalho=true'}
                telaManual={MANUAIS_CATEGORIAS.LISTAGEM_OBRA_PLANO_TRABALHO}
              />
            )}
            <Grid container spacing={3}>
              <Grid item xl={3} lg={3} md={3} xs={12}>
                <Input
                  id="term"
                  label="term"
                  placeholder="Filtro"
                  value={term}
                  fullWidth
                  onChange={handleChange('term')}
                />
              </Grid>
              {(authActions.isGestor() || authActions.isPrefeitura()) &&
                <Grid item xl={9} lg={9} md={9} xs={12} align="right">
                  <FormControl>
                    <FormLabel>
                      <Typography variant="subtitle1">Filtrar por Status </Typography>
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      onChange={handleChangeRadio('status')}
                      value={status}
                    >
                      <FormControlLabel value="0" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(null)
                        }
                      }} />} label={<Typography variant="subtitle2">Em lançamento</Typography>} />

                      <FormControlLabel value="1" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(1)
                        }
                      }} />} label={<Typography variant="subtitle2">Pendente</Typography>} />

                      <FormControlLabel value="2" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(2)
                        }
                      }} />} label={<Typography variant="subtitle2">Recebendo orçamentos</Typography>} />

                      <FormControlLabel value="3" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(3)
                        }
                      }} />} label={<Typography variant="subtitle2">Em execução</Typography>} />

                      <FormControlLabel value="4" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(4)
                        }
                      }} />} label={<Typography variant="subtitle2">Recusada</Typography>} />

                      <FormControlLabel value="5" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(5)
                        }
                      }} />} label={<Typography variant="subtitle2">Concluída</Typography>} />

                      <FormControlLabel value="6" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(6)
                        }
                      }} />} label={<Typography variant="subtitle2">Avaliada</Typography>} />

                      <FormControlLabel value="8" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(8)
                        }
                      }} />} label={<Typography variant="subtitle2">Deserta</Typography>} />

                      <FormControlLabel value="9" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: getCor(9)
                        }
                      }} />} label={<Typography variant="subtitle2">Sem sucesso</Typography>} />

                      <FormControlLabel value="" control={<Radio size="sm" sx={{
                        '&.Mui-checked': {
                          color: '#000'
                        }
                      }} />} label={<Typography variant="subtitle2">Todas</Typography>} />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              }
            </Grid>
            <br />
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Data Início</TableCell>
                    <TableCell>Tipo</TableCell>
                    <TableCell>Título</TableCell>
                    <TableCell>Órgão</TableCell>
                    <TableCell align="right">Valor Referência</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {obra && undefined !== obra && obra.length
                    ? obra.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.dataInicio ? moment(n.dataInicio).format("DD/MM/YYYY") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.tipoObra ? n.tipoObra.nome : ''}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                              {n.urgencia ? (
                                <span style={{ backgroundColor: '#ef5350', borderRadius: 8, padding: 4, color: '#fff', fontSize: 13, marginRight: 4, fontWeight: 400 }}>
                                  URGENTE
                                </span>
                              ) : null}
                              <span style={{ verticalAlign: 'middle' }}> {n.titulo ? `#${n.id} ` + n.titulo.toUpperCase() : ''}</span>
                            </div>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.orgao ? n.orgao.nome.toUpperCase() : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            {n.ultimoEvento != STATUS_SERVICO.LANCAMENTO ? Intl.NumberFormat('pt-BR', {
                              style: 'currency',
                              currency: 'BRL'
                            }).format(n.valorReferenciaComBdi) : '--,--'}
                          </TableCell>
                          <TableCell component="th" scope="row" align="left">
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <CircleIcon sx={{ color: getCor(n.ultimoEvento), fontSize: 12, marginRight: 1 }} />
                                <span>{getNomeUltimoEvento(n.ultimoEvento, n, true)}</span>
                                {n.obrasEmpenhos && n.obrasEmpenhos.length > 0 &&
                                  <Tooltip title='Este plano de trabalho possui um empenho cadastrado'>
                                    <div style={{ marginLeft: 2, padding: 3, alignItems: 'center', marginTop: 4 }}>
                                      <TurnedInNotIcon />
                                    </div>
                                  </Tooltip>
                                }
                              </div>
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                {getNomeUltimoEvento(n.ultimoEvento, n, true) == 'EM EXECUÇÃO' ?
                                  <Tooltip title='Percentual de conclusão do plano de trabalho'>
                                    <div style={{ backgroundColor: '#B0BEC5', borderRadius: 8, padding: 3, color: '#212121', display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 4 }}>
                                      <CheckIcon sx={{ fontSize: 18, marginRight: 1 }} />
                                      <span style={{ fontWeight: 500 }}>{n.percentualConclusaoObra}%</span>
                                    </div>
                                  </Tooltip>
                                  : null
                                }
                                {n.totalObraMedicoesPendentes > 0 &&
                                  <Tooltip title='Total de medições pendentes para análise'>
                                    <DivStyled>
                                      <TimeIcon sx={{ fontSize: 18, marginRight: 1 }} />
                                      <span style={{ fontWeight: 500 }}>{n.totalObraMedicoesPendentes}</span>
                                    </DivStyled>
                                  </Tooltip>
                                }
                              </div>
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <AccessControl
                              rule={'obras.list'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(`/obra/${n.id}`)
                                  }
                                  size="small"
                                >
                                  <Tooltip title={'Editar'}>
                                    <Tooltip >
                                      <EditIcon />
                                    </Tooltip>
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'obras.delete'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <IconButton
                                  aria-label="Delete"
                                  onClick={() => handleOpenModalMotivo(n.id)}
                                  size="small"
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={7} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component="div"
              count={totalRegistros}
              labelRowsPerPage="Linhas por página"
              labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeLimit}
            />
          </div >
        )
        }
        no={() => <AccessDenied />}
      />
      {
        (modalMotivo ?
          <ObraMotivoModal
            toggle={toggleModalMotivo}
            modal={modalMotivo}
            props={props}
            idObra={idObra}
            isPlanoTrabalho={true}
          />
          :
          null
        )
      }
    </Page >
  )
}
ObraPlanoTrabalho.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return {
    obra: state.obra
  }
}
const connectedObraPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraPlanoTrabalho))
)
export { connectedObraPage as ObraPlanoTrabalho }
