import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import moment from 'moment'
import { connect, useDispatch, useSelector } from 'react-redux'
import clsx from 'clsx'
import DoneIcon from '@mui/icons-material/Done';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CloseIcon from '@mui/icons-material/Close';
import { useConfirm } from 'material-ui-confirm'
import PropTypes from 'prop-types'
import { withStyles } from '@mui/styles'
import getInitials from 'utils/getInitials'
import HelpIcon from '@mui/icons-material/Help';
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import {
  Autocomplete,
  Backdrop,
  Card,
  CardHeader,
  CardActions,
  CircularProgress,
  Divider,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Grid,
  MenuItem,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip
} from '@mui/material'
import {
  ValidatorForm,
  TextValidator,
  SelectValidator
} from 'react-material-ui-form-validator'
import {
  authActions,
  obraAction,
  obraEtapaAction,
  parametroAction,
  orgaoAction,
  obraOrcamentoItemAction,
  obraMedicaoAction,
  tipoObraAction
} from '../../_actions'
import { toast } from 'react-toastify'
import { withRouter } from 'react-router-dom'
import { uploadImage } from '_services'
import { STATUS_SERVICO, errorsUploadImage } from 'utils'
import { getNomeUltimoEvento } from 'utils'
import { styled } from '@mui/material/styles';
import { history, stringUtils, MANUAIS_CATEGORIAS } from '../../utils'
import { Page, MapContainer, ImageUploader } from 'components'
import { isEmpty } from 'lodash'
import SaveIcon from '@mui/icons-material/Save';
import {
  ObraAjudaModal,
  ObraAnexoEdit,
  ObraEtapaEdit,
  ObraEtapaItemEdit,
  ObraFinanceiroEdit,
  ObraMedicaoEdit,
  ObraMotivoAlteracaoHorasRecebimentoOrcamentosModal,
  ObraOrcamentoItemEdit,
} from '../'
import ObraSummaryCard from './obraSummaryCard.component'
import CustomButton from '../../components/CustomButton/CustomButton'
import ObraOcorrenciasList from './obraOcorrenciasList.component';
import { ObraAvaliacaoEdit } from './obraAvaliacaoEdit.component';
import { HelpModal } from '../../components';

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
      paddingTop: theme.spacing(1)
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

function isEditable(e) {
  if (e == '1' || e == '2' || e == '3' || e == '4' || e == '5' || e == '6' || e == '8' || e == '9') {
    return false
  }
  else return true
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const SwitchBlue = styled(Switch)(() => ({
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      color: '#1C5585',
    }
  }
}));

const ObraEdit = (props) => {

  const dispatch = useDispatch()
  const confirm = useConfirm()
  const [ready, setReady] = React.useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [initialHorasRecebimentoOrcamentos, setInitialHorasRecebimentoOrcamentos] = useState(props.obra.horasRecebimentoOrcamentos);
  const [payloadObra, setPayloadObra] = useState(null);

  const [images, setImages] = useState([])
  const [imagem, setImagem] = useState('')
  const [open, setOpen] = useState(false)
  const [isObra, setIsObra] = useState(false)
  const [isProjeto, setIsProjeto] = useState(false)
  const [isReparo, setIsReparo] = useState(false)

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isCronograma = location.state?.isCronograma ? location.state.isCronograma : false;
  const isCobranca = location.state?.isCobranca ? location.state.isCobranca : false;
  const isPlanoTrabalho = searchParams.get('planoTrabalho') === 'true';
  const isReparoPrevencao = searchParams.get('reparoPrevencao') === 'true';

  const horasRecebimentoOrcamentosParametro = useSelector((state) => (state.dashboard ? state.dashboard.dashboard.horasRecebimentoOrcamentos : undefined))
  const duracaoObra = useSelector((state) => state.obra ? state.obra.duracaoObra : 30)

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const [modalAlteracaoHorasRecebimentoOrcamentos, setModalAlteracaoHorasRecebimentoOrcamentos] = useState(false);
  const toggleModalAlteracaoHorasRecebimentoOrcamentos = () => setModalAlteracaoHorasRecebimentoOrcamentos(!modalAlteracaoHorasRecebimentoOrcamentos);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const handleOpenModalMotivoAlteracaoHorasRecebimentoOrcamentos = () => {
    // Verifica se horasRecebimentoOrcamentos da obra está definido e diferente de 0
    if (props.obra.horasRecebimentoOrcamentos && props.obra.horasRecebimentoOrcamentos != 0) {
      //se a obra já tiver id, valida com o valor informado no campo anteriormente
      if (params.id) {
        if (initialHorasRecebimentoOrcamentos != props.obra.horasRecebimentoOrcamentos && (props.obra.motivoMudancaHorasRecebimentoOrcamentos == '' || !props.obra.motivoMudancaHorasRecebimentoOrcamentos)) {
          toggleModalAlteracaoHorasRecebimentoOrcamentos();
        } else {
          handleSubmit();
        }
      } else {
        //se for obra nova, valida com o parâmetro
        if (horasRecebimentoOrcamentosParametro != props.obra.horasRecebimentoOrcamentos) {
          toggleModalAlteracaoHorasRecebimentoOrcamentos();
        } else {
          handleSubmit();
        }
      }
    }
  };

  const { classes, obra, obraOrcamento, className, ...rest } = props

  const { match: { params } } = props;
  const formRef = React.createRef(null);// Replace this with the dynamic number of cells you want

  const { orgao } = useSelector(
    (state) => state.orgao)

  const { tipoObra } = useSelector(
    (state) => state.tipoObra)

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  const obras = useSelector(
    (state) => state.obra.obra)

  const [tabValue, setTabValue] = useState(0);

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };

  const textObra = 'Obra';
  const textCompraMaterial = 'Compra de Material';
  const textPlanoTrabalho = 'Plano de Trabalho';
  const textReparoPrevencao = 'Reparo/Prevenção';

  function notIsUsuarioOrgaoPublico() {
    if (authActions.isUsuarioOrgaoPublico()) {
      return false
    }
    else return true
  }

  const removeImage = () => {
    confirm({
      title: 'Deseja remover a imagem?',
      description: '',
      confirmationText: 'Sim, quero remover!',
      cancellationText: 'Não'
    }).then(() => {
      let payload = {
        ...obra,
        caminhoImagem: null
      }
      dispatch(
        obraAction.editObraInfo(obra.id, payload, false)
      )
      var event2 = {
        type: 'select',
        target: {
          value: null
        }
      };
      dispatch(obraAction.onChangeProps('caminhoImagem', event2))
      setImagem(null)
    })
  }

  const onChangeImage = (imageList) => {
    // data for submit
    setOpen(true)
    setImages(imageList)
    uploadImage(imageList[0])
      .then((response) => {

        let payload = {
          ...obra,
          caminhoImagem: response.toString()
        }

        if (obra.id !== 0) {
          dispatch(
            obraAction.editObraInfo(obra.id, payload, false)
          )
        }

        setTimeout(function () {

          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(obraAction.onChangeProps('caminhoImagem', event2))

          setImagem(response)
          setOpen(false)

        }, 1000);

      })
      .catch((err) => {
        console.log(err)
        setOpen(false)
      })
  }

  const handleInputChange = (event, value) => {
    if (isEditable(props.obra.ultimoEvento)) {
      setIsTyping(value.length > 0);
      setIsOpen(value.length > 0 && isTyping);
    }
  };

  const handleTextFieldChange = (e) => {
    const value = e.target.value;
    handleChange('titulo')(e);
    if (isEditable(props.obra.ultimoEvento)) {
      setIsTyping(true);
      setIsOpen(value.length > 0);
    }
  };

  useEffect(() => {
    setImagem(obra.caminhoImagem)
  }, [obra.caminhoImagem, open]);

  useEffect(() => {

    if (obra.id && obra.tipoObra?.obra) {
      setIsObra(obra.tipoObra.obra)
      dispatch(tipoObraAction.getTipoObraSelect(isObra, false, false))
    }

    if (obra.id && obra.tipoObra?.projeto) {
      setIsProjeto(obra.tipoObra.projeto)
      dispatch(tipoObraAction.getTipoObraSelect(false, isProjeto, false))
    }

    if (obra.id && obra.tipoObra?.reparo) {
      setIsReparo(obra.tipoObra.reparo)
      dispatch(tipoObraAction.getTipoObraSelect(false, false, isReparo))
    }
  }, [obra.id]);

  useEffect(() => {
    dispatch(obraAction.getObraSelectTitle({ IdOrgao: idOrgao }));
  }, [idOrgao]);

  useEffect(() => {
    dispatch(tipoObraAction.getTipoObraSelect(isObra, isProjeto, isReparo))
  }, [isObra, isProjeto, isReparo]);

  useEffect(() => {

    dispatch(obraAction.clear())
    dispatch(obraOrcamentoItemAction.clear())
    dispatch(obraMedicaoAction.clear())
    dispatch(obraEtapaAction.clear())
    dispatch(orgaoAction.getOrgaoSelect())

    dispatch(parametroAction.getTabelaPadraoObra())

    if (params.id) {
      dispatch(obraAction.getObraById(params.id))
    } else {
      if (idOrgao == '') {
        toast.error('Selecione um órgão')
        history.push('obras')
      }
    }

    if (isCronograma) {
      if (((authActions.isEmpresa() || authActions.isFiscal()) && notIsUsuarioOrgaoPublico())) {
        setTabValue(0);
      } else {
        setTabValue(5);
      }
    }

    if (authActions.isGestor() || authActions.isPrefeitura()) {
      if (isCobranca) {
        setTabValue(6);
      }
    } else if (authActions.isEmpresa()) {
      if (isCobranca) {
        setTabValue(2);
      }
    }

    const isObraParam = stringUtils.getObraParam(props.location.search) != null ? stringUtils.getObraParam(props.location.search) : false;
    setIsObra(isObraParam);
    setIsProjeto(isPlanoTrabalho);
    setIsReparo(isReparoPrevencao);

    const interval = setInterval(() => {
      setReady(true)
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  const handleDevolveObraRevisao = (id) => {
    confirm({
      title: 'Você deseja devolver a obra para revisão?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Retornando status...' : 'Sim, retornar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAction.editObraDevolveParaRevisao(id))
    })
  }

  const handleChange = (prop) => (event) => {
    dispatch(obraAction.onChangeProps(prop, event))
  }

  useEffect(() => {
    if (props.obra.duracaoObra && props.obra.duracaoObra != 0 && props.obra.dataInicio && props.obra.dataInicio != '') {
      const newDataTerminoObra = moment(props.obra.dataInicio).add(props.obra.duracaoObra, 'days').format('YYYY-MM-DD')

      const fakeEvent = {
        type: 'text',
        target: {
          value: newDataTerminoObra
        }
      }
      dispatch(obraAction.onChangeProps('dataTermino', fakeEvent))
    }
  }, [props.obra.duracaoObra, props.obra.dataInicio])

  useEffect(() => {
    setPayloadObra({
      id: params.id,
      idUsuario: props.obra.idUsuario,
      dataInicio: moment(props.obra.dataInicio).toISOString(),
      dataTermino: moment(props.obra.dataTermino).toISOString(),
      idOrgao: (idOrgao != 0 ? idOrgao : props.obra.idOrgao),
      descritivo: props.obra.descritivo,
      caminhoImagem: props.obra.caminhoImagem,
      titulo: props.obra.titulo,
      latitude: props.obra.latitude,
      longitude: props.obra.longitude,
      bdi: props.obra.bdi,
      idTipoObra: props.obra.idTipoObra,
      urgencia: props.obra.urgencia,
      horasRecebimentoOrcamentos: props.obra.horasRecebimentoOrcamentos,
      motivoMudancaHorasRecebimentoOrcamentos: props.obra.motivoMudancaHorasRecebimentoOrcamentos,
      onerado: props.obra.onerado,
      ultimoEvento: props.obra.ultimoEvento,
      obrasAnexos: props.obra.obrasAnexos,
      obrasEtapas: [
        {
          id: props.obraEtapa.id,
          dataInicio: moment(props.obra.dataInicio).toISOString(),
          dataTermino: moment(props.obra.dataTermino).toISOString(),
          descritivo: 'RELAÇÃO DOS MATERIAIS E SERVIÇOS DA OBRA',
          titulo: 'DISCRIMINAÇÃO DOS ITENS',
          periodos: '100'
        }
      ],
      obrasCoordenadas: [{
        indice: 1,
        idObra: params.id,
        latitude: props.obra.latitude ? props.obra.latitude : 0,
        longitude: props.obra.longitude ? props.obra.longitude : 0
      }]
    })
  }, [props.obra])

  const handleSubmit = (event) => {

    if (params.id) {
      dispatch(obraAction.editObraInfo(params.id, payloadObra, idOrgao, isObra, isProjeto, isReparo))
    } else {
      dispatch(obraAction.createObra(payloadObra, idOrgao, isObra, isPlanoTrabalho, isReparoPrevencao))
    }
  };

  const handleAprovaObra = () => {
    confirm({
      title: 'Você deseja aprovar esta Obra?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Aprovando Obra...' : 'Sim, aprovar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAction.editObraAprova(params.id, idOrgao, isObra, isPlanoTrabalho, isReparoPrevencao))
    })
  }

  const handleRecusaObra = () => {
    confirm({
      title: 'Você deseja recusar esta Obra?',
      description: 'Essa operação é irreversível',
      disabled: props.obra.isLoading,
      confirmationText: props.obra.isLoading ? 'Recusando Obra...' : 'Sim, recusar!',
      cancellationText: 'Não!'
    }).then(() => {
      dispatch(obraAction.editObraRecusa(params.id, idOrgao, isObra, isPlanoTrabalho, isReparoPrevencao))
    })
  }

  function InsertText() {
    return (
      <Typography variant="h5">
        <b>{notIsUsuarioOrgaoPublico() ? (isPlanoTrabalho ? `Novo ${textPlanoTrabalho}` : isReparoPrevencao ? `Novo ${textReparoPrevencao}` : isObra ? `Nova ${textObra}` : `Nova ${textCompraMaterial}`) : 'Nova Solicitação de Obra'}</b>
      </Typography>
    )
  }

  function EditText() {
    return (
      <Typography variant="h5">
        <b>{`Editar ${isReparo ? textReparoPrevencao : isProjeto ? textPlanoTrabalho : isObra ? textObra : textCompraMaterial}`}</b>
      </Typography>
    )
  }
  function SegHeader() {
    if (params.id) {
      return <EditText />
    }
    return <InsertText />
  }

  return (
    <Page
      className={classes.root}
      title={
        params.id
          ? `Gerência de Obra - Editar ${isReparoPrevencao ? textReparoPrevencao : isPlanoTrabalho ? textPlanoTrabalho : isObra ? textObra : textCompraMaterial}`
          : `Gerência de Obra - Nova ${isReparoPrevencao ? textReparoPrevencao : isPlanoTrabalho ? textPlanoTrabalho : isObra ? textObra : textCompraMaterial}`
      }
    >
      <Grid
        {...rest}
        className={clsx(classes.root, className)}
        container
        spacing={3}
      >
        <Grid item md={12} xs={12}>
          <Card>
            {params.id && notIsUsuarioOrgaoPublico() &&
              <ObraSummaryCard idObra={params.id} latitude={props.obra.latitude} longitude={props.obra.longitude} />
            }
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              {((authActions.isGestor() || authActions.isPrefeitura()) && notIsUsuarioOrgaoPublico() && props.obra.id) &&
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab label="CADASTRO" {...a11yProps(0)} />
                  <Tab label="GRUPOS" {...a11yProps(1)} />
                  <Tab label="ITENS" {...a11yProps(2)} />
                  <Tab label="ANEXOS" {...a11yProps(3)} />
                  <Tab label="ORÇAMENTOS" {...a11yProps(4)} />
                  <Tab label="MEDIÇÕES" {...a11yProps(5)} />
                  {!authActions.isPrefeitura() &&
                    <Tab label="FINANCEIRO" {...a11yProps(authActions.isPrefeitura() ? 6 : 7)} />
                  }
                  <Tab label="OCORRÊNCIAS" {...a11yProps(7)} />
                  {(authActions.isPrefeitura() || authActions.isGestor()) && props.obra.ultimoEvento == STATUS_SERVICO.CONCLUIDO &&
                    < Tab label="AVALIAÇÃO" {...a11yProps(8)} />
                  }
                </Tabs>
              }
              {((authActions.isEmpresa() || authActions.isFiscal()) && notIsUsuarioOrgaoPublico()) &&
                <Tabs value={tabValue} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab label="MEDIÇÕES" {...a11yProps(0)} />
                  {authActions.isEmpresa() && <Tab label="OCORRÊNCIAS" {...a11yProps(1)} />}
                  {authActions.isEmpresa() && <Tab label="COBRANÇAS DA OBRA" {...a11yProps(2)} />}
                  {authActions.isEmpresa() && props.obra.ultimoEvento != STATUS_SERVICO.LANCAMENTO && props.obra.ultimoEvento != STATUS_SERVICO.PENDENTE && props.obra.ultimoEvento != STATUS_SERVICO.RECEBENDO_ORCAMENTOS ? <Tab label="ORÇAMENTOS" {...a11yProps(3)} /> : null}
                </Tabs>
              }
            </Box>
            {(authActions.isGestor() || authActions.isPrefeitura()) &&
              <>
                <TabPanel value={tabValue} index={0}>
                  <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <CardHeader
                      subheader={`Cadastro de ${isReparo ? textReparoPrevencao : isProjeto ? textPlanoTrabalho : isObra ? textObra : textCompraMaterial}`}
                      title={<SegHeader />}
                    />
                    <Grid item lg={1} md={1} xl={1} xs={12}>
                      <IconButton
                        aria-label="Ajuda"
                        component="a"
                        onClick={() =>
                          handleOpenModalHelp()
                        }
                        size="small"
                      >
                        <Tooltip title={'Ajuda'}>
                          <Tooltip>
                            <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                          </Tooltip>
                        </Tooltip>
                      </IconButton>
                    </Grid>
                  </Grid>
                  <ValidatorForm
                    className={classes.form}
                    ref={formRef}
                    id="formObra"
                    onSubmit={() => handleOpenModalMotivoAlteracaoHorasRecebimentoOrcamentos(true)}
                  >
                    <Divider />
                    <Grid
                      container
                      spacing={3}
                    >
                      <Grid item lg={4} md={6} xl={3} xs={12}>
                        <Grid item md={12} xs={12} style={{ paddingBottom: 15 }}>
                          <Backdrop className={classes.backdrop} open={open}>
                            <Grid
                              container
                              spacing={3}
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                              alignContent="center"
                              wrap="nowrap"
                            >
                              <Grid item>
                                <CircularProgress color="primary" />
                              </Grid>
                              <Grid item>
                                <Typography variant="h4" color="textPrimary">
                                  Enviando foto...
                                </Typography>
                              </Grid>
                            </Grid>
                          </Backdrop>
                          <ImageUploader
                            images={images}
                            onChangeImage={onChangeImage}
                            onError={errorsUploadImage}
                            acceptType={['jpeg', 'jpg', 'png', 'webp', 'jfif', 'bmp', 'tiff']}
                            maxFileSize={5000000}
                            resolutionWidth={5000}
                            resolutionHeight={5000}
                            disabled={isEditable(obra.ultimoEvento) == false}
                            resolutionType={"less"}
                            removeImage={removeImage}
                            props={obra}
                            isObra={true}
                            imagem={imagem}
                            getInitials={getInitials}
                            classes={classes}
                            rest={rest}
                            className={className}
                          />
                        </Grid>
                      </Grid>
                      <Grid item lg={8} md={6} xl={9} xs={12}>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="idOrgao"
                            label="Órgão *"
                            margin="normal"
                            value={params.id ? props.obra.idOrgao : idOrgao != 0 ? idOrgao : ''}
                            onChange={handleChange('idOrgao') || 0}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            disabled={true}
                            labelId="idOrgao"
                          >
                            <MenuItem disabled value="">
                              <em>Órgão</em>
                            </MenuItem>
                            {!isEmpty(orgao) &&
                              undefined !== orgao &&
                              orgao.length &&
                              orgao.map((row) => (
                                <MenuItem key={row.value} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))}
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={6} md={6} xl={6} xs={12}>
                          <SelectValidator
                            validators={['required']}
                            errorMessages={['Campo obrigatório!']}
                            variant="outlined"
                            id="idTipoObra"
                            label={(isProjeto || isReparo) ? 'Tipo de Serviço *' : 'Tipo de Obra *'}
                            margin="normal"
                            value={props.obra.idTipoObra ? props.obra.idTipoObra : ''}
                            onChange={handleChange('idTipoObra') || 0}
                            InputLabelProps={{
                              shrink: true
                            }}
                            fullWidth
                            displayEmpty
                            disabled={isEditable(obra.ultimoEvento) == false}
                            labelId="idTipoObra"
                          >
                            <MenuItem disabled value="">
                              <em>{(isProjeto || isReparo) ? 'Tipo de Serviço *' : 'Tipo de Obra *'}</em>
                            </MenuItem>
                            {!isEmpty(tipoObra) &&
                              undefined !== tipoObra &&
                              tipoObra.length &&
                              tipoObra.map((row) => (
                                <MenuItem key={row.value} value={row.value}>
                                  {row.text}
                                </MenuItem>
                              ))
                            }
                          </SelectValidator>
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <Autocomplete
                            freeSolo
                            disabled={!isEditable(props.obra.ultimoEvento)}
                            id="free-solo-2-demo"
                            className={classes.textField}
                            options={obras?.map((option) => option.text) || []}
                            filterSelectedOptions
                            open={isOpen && isEditable(props.obra.ultimoEvento)}
                            renderInput={(params) => (
                              <TextValidator
                                {...params}
                                label="Título *"
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                                onChange={handleTextFieldChange}
                                onInput={stringUtils.toInputUppercase}
                                value={props.obra.titulo || ''}
                                variant="outlined"
                                margin="normal"
                                validators={['required']}
                                errorMessages={['Campo obrigatório!']}
                              />
                            )}
                            onInputChange={handleInputChange}
                            onClose={() => setIsOpen(false)} // Fecha o menu ao sair do campo
                            value={props.obra.titulo || ''}
                          />
                        </Grid>
                        <Grid item lg={12} md={12} xl={12} xs={12}>
                          <TextValidator
                            className={classes.textField}
                            fullWidth
                            multiline
                            disabled={isEditable(obra.ultimoEvento) == false}
                            minRows={2}
                            InputLabelProps={{
                              shrink: true
                            }}
                            id="descritivo"
                            label="Descritivo"
                            margin="normal"
                            onInput={stringUtils.toInputUppercase}
                            onChange={handleChange('descritivo')}
                            value={props.obra.descritivo || ''}
                            variant="outlined"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={3}
                    >
                      {notIsUsuarioOrgaoPublico() ?
                        <>
                          <Grid item lg={12} md={12} xl={12} xs={12} style={{ paddingTop: 15 }}>
                            <Divider />
                          </Grid>
                          {isObra &&
                            <Grid item lg={3} md={3} xl={3} xs={12}>
                              <TextValidator
                                className={classes.textField}
                                fullWidth
                                type="number"
                                id="bdi"
                                label="Bdi *"
                                margin="normal"
                                onChange={handleChange('bdi')}
                                value={props.obra.bdi || 0}
                                disabled={isEditable(obra.ultimoEvento) == false}
                                variant="outlined"
                                validators={['required']}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                InputProps={{
                                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  inputProps: {
                                    min: 0,
                                    step: 0.001
                                  }
                                }}
                                errorMessages={['Campo obrigatório!']}
                              />
                            </Grid>
                          }
                          <Grid item lg={3} md={3} xl={3} xs={12}>
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              type="date"
                              id="dataInicio"
                              disabled={isEditable(obra.ultimoEvento) == false}
                              label={isObra ? "Data de Início *" : "Previsão de Início *"}
                              margin="normal"
                              onChange={handleChange('dataInicio')}
                              value={props.obra.dataInicio ? moment(props.obra.dataInicio).format("yyyy-MM-DD") : ''}
                              variant="outlined"
                              validators={['required']}
                              InputLabelProps={{
                                shrink: true
                              }}
                              errorMessages={['Campo obrigatório!']}
                            />
                          </Grid>
                          <Grid item lg={2} md={2} xl={2} xs={12}>
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              type="number"
                              disabled={isEditable(obra.ultimoEvento) == false}
                              id="duracaoObra"
                              label={(isProjeto || isReparo) ? "Previsão de duração do serviço (em dias) *" : isObra ? "Duração da obra (em dias) *" : "Vigência do fornecimento (em dias) *"}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true
                              }}
                              validators={['minNumber:1']}
                              errorMessages={['O valor mínimo é 1 dia']}
                              onChange={handleChange('duracaoObra')}
                              value={props.obra.duracaoObra || duracaoObra}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item lg={3} md={3} xl={3} xs={12}>
                            <TextValidator
                              className={classes.textField}
                              fullWidth
                              type="number"
                              id="horasRecebimentoOrcamentos"
                              label="Horas Para Recebimento dos Orçamentos"
                              margin="normal"
                              InputLabelProps={{
                                shrink: true
                              }}
                              validators={['required', 'minNumber:0']}
                              errorMessages={['Campo obrigatório', 'Valor precisa ser maior ou igual a 0']}
                              onChange={handleChange('horasRecebimentoOrcamentos')}
                              value={props.obra.horasRecebimentoOrcamentos || ''}
                              variant="outlined"
                            />
                          </Grid>
                          <Grid item lg={1} md={1} xl={1} xs={12} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <Tooltip title={'Valor informado nos parâmetros do sistema: ' + horasRecebimentoOrcamentosParametro}>
                              <Tooltip>
                                <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                              </Tooltip>
                            </Tooltip>
                          </Grid>
                          {isObra &&
                            <Grid item lg={3} md={3} xl={3} xs={12}>
                              <SelectValidator
                                variant="outlined"
                                id="desonerado"
                                style={{ marginTop: 15 }}
                                InputLabelProps={{
                                  shrink: true
                                }}
                                value={props.obra.onerado}
                                onChange={handleChange('onerado') || false}
                                label="Desonerado"
                                disabled={isEditable(obra.ultimoEvento) == false}
                                fullWidth
                                labelId="desonerado"
                                displayEmpty
                              >
                                <MenuItem disabled value="">
                                  <em>Desonerado</em>
                                </MenuItem>
                                <MenuItem value={true}>NÃO DESONERADO</MenuItem>
                                <MenuItem value={false}>DESONERADO</MenuItem>
                              </SelectValidator>
                            </Grid>
                          }
                          {params.id &&
                            <Grid item lg={3} md={3} xl={3} xs={12}>
                              <TextField
                                className={classes.textField}
                                fullWidth
                                label="Status"
                                margin="normal"
                                variant="outlined"
                                value={obra && obra.ultimoEvento ? getNomeUltimoEvento(obra.ultimoEvento, null, false) : 'EM LANÇAMENTO'}
                              />
                            </Grid>
                          }
                          <Grid item lg={3} md={4} xl={3} xs={12} style={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
                            <FormControlLabel
                              control={
                                <SwitchBlue
                                  checked={props.obra.urgencia}
                                  onChange={handleChange('urgencia')}
                                  disabled={isEditable(obra.ultimoEvento) == false}
                                />
                              }
                              label={`${isObra ? 'Obra de' : isProjeto ? 'Plano de Trabalho de ' : isReparo ? 'Reparo/Prevenção de' : 'Fornecimento com'} Urgência`}
                            />
                          </Grid>
                        </>
                        :
                        null
                      }
                      <Grid item lg={12} md={12} xl={12} xs={12}>
                        <Typography style={{ fontSize: "10px", paddingLeft: "6px" }}>
                          * Campos obrigatórios
                        </Typography>
                      </Grid>
                      <Grid item lg={12} md={12} xl={12} xs={12} >
                        <Divider />
                      </Grid>
                      {ready &&
                        <Grid item lg={12} md={12} xl={12} xs={12} style={{ position: 'relative' }}>
                          <Box style={{ display: 'relative', left: 0, right: 0, bottom: 0 }}>
                            <MapContainer latitude={props.obra.latitude} longitude={props.obra.longitude} nome={props.obra.titulo} classes={props.classes} obra={props.obra} idObra={props.obra.id} obraCoordenada={props.obraCoordenada} isEditable={isEditable} />
                          </Box>
                        </Grid>
                      }
                    </Grid>
                    <CardActions style={{ marginTop: 315 }}>
                      <Grid item lg={12} md={12} xl={12} xs={12} style={{
                        textAlign: 'end'
                      }}>
                        <CustomButton
                          variant="outlined"
                          onClick={() => history.push('/obras')}
                        >
                          Cancelar
                        </CustomButton>
                        {notIsUsuarioOrgaoPublico() ?
                          obra.id && obra.ultimoEvento == STATUS_SERVICO.PENDENTE && authActions.isGestor() ?
                            <>
                              <CustomButton
                                variant="outlined"
                                onClick={handleRecusaObra}
                                disabled={props.obra.isLoading}
                                startIcon={<CloseIcon />}
                                endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                              >
                                {props.obra.isLoading ? '...' : 'Recusar'}
                              </CustomButton>
                              <CustomButton
                                variant="outlined"
                                onClick={handleAprovaObra}
                                disabled={props.obra.isLoading}
                                startIcon={<DoneIcon />}
                                endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                              >
                                {props.obra.isLoading ? '...' : 'Aprovar'}
                              </CustomButton>
                              <CustomButton
                                variant="outlined"
                                onClick={() => handleDevolveObraRevisao(params.id)}
                                startIcon={<EditNoteIcon />}
                                endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                              >
                                {props.obra.isLoading ? '...' : 'Devolver obra para ajustes'}
                              </CustomButton>
                            </>
                            : null
                          : null}
                        < CustomButton
                          type="submit"
                          variant="contained"
                          dark={true}
                          disabled={props.obra.isLoading || obra.ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS}
                          startIcon={<SaveIcon />}
                          endIcon={props.obra.isLoading && <CircularProgress size={24} />}
                        >
                          {params.id ? (props.obra.isLoading ? 'Atualizando Obra...' : 'Atualizar') : (props.obra.isLoading ? 'Salvando Obra...' : 'Salvar')}
                        </CustomButton>
                      </Grid>
                    </CardActions>
                  </ValidatorForm>
                </TabPanel >
                {notIsUsuarioOrgaoPublico() &&
                  <>
                    <TabPanel value={tabValue} index={1}>
                      <ObraEtapaEdit idObra={params.id} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={2}>
                      <ObraEtapaItemEdit idObra={params.id} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={3}>
                      <ObraAnexoEdit idObra={params.id} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={4}>
                      <ObraOrcamentoItemEdit props={props} idObra={params.id} obra={props.obra.obra} />
                    </TabPanel>
                    <TabPanel value={tabValue} index={5}>
                      <ObraMedicaoEdit props={props} idObra={params.id} obra={props.obra.obra} />
                    </TabPanel>
                  </>
                }
                {!authActions.isPrefeitura() && notIsUsuarioOrgaoPublico() &&
                  <TabPanel value={tabValue} index={6}>
                    <ObraFinanceiroEdit props={props} idObra={params.id} obra={props.obra.obra} />
                  </TabPanel>
                }
                <TabPanel value={tabValue} index={authActions.isPrefeitura() ? 6 : 7}>
                  <ObraOcorrenciasList props={props} idObra={params.id} obra={props.obra.obra} classes={props.classes} />
                </TabPanel>
                {(authActions.isPrefeitura() || authActions.isGestor()) &&
                  <TabPanel value={tabValue} index={authActions.isPrefeitura() ? 7 : 8}>
                    <ObraAvaliacaoEdit props={props} idObra={params.id} obra={props.obra.obra} />
                  </TabPanel>
                }
              </>
            }
            {((authActions.isEmpresa() || authActions.isFiscal()) && notIsUsuarioOrgaoPublico()) &&
              <TabPanel value={tabValue} index={0}>
                <ObraMedicaoEdit props={props} idObra={params.id} obra={props.obra.obra} />
              </TabPanel>
            }
            {authActions.isEmpresa() && notIsUsuarioOrgaoPublico() &&
              <>
                <TabPanel value={tabValue} index={1}>
                  <ObraOcorrenciasList props={props} idObra={params.id} obra={props.obra.obra} classes={props.classes} />
                </TabPanel>
                <TabPanel value={tabValue} index={2}>
                  <ObraFinanceiroEdit props={props} idObra={params.id} obra={props.obra.obra} />
                </TabPanel>
                {props.obra.ultimoEvento != STATUS_SERVICO.LANCAMENTO && props.obra.ultimoEvento != STATUS_SERVICO.PENDENTE && props.obra.ultimoEvento != STATUS_SERVICO.RECEBENDO_ORCAMENTOS &&
                  <TabPanel value={tabValue} index={3}>
                    <ObraOrcamentoItemEdit props={props} idObra={params.id} obra={props.obra.obra} />
                  </TabPanel>
                }
              </>
            }
          </Card >
        </Grid >
      </Grid >
      {
        (modal ?
          <ObraAjudaModal toggle={toggle} modal={modal} props={props} />
          :
          null
        )
      }

      {
        (modalAlteracaoHorasRecebimentoOrcamentos ?
          <ObraMotivoAlteracaoHorasRecebimentoOrcamentosModal
            toggle={toggleModalAlteracaoHorasRecebimentoOrcamentos}
            modal={modalAlteracaoHorasRecebimentoOrcamentos}
            props={props}
            isObra={isObra}
            payloadObra={payloadObra}
            setPayloadObra={setPayloadObra}
            idObra={params.id}
            isPlanoTrabalho={isPlanoTrabalho}
            isReparoPrevencao={isReparoPrevencao}
          />
          :
          null
        )
      }

      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_CADASTRO_OBRA}
          />
          :
          null
        )
      }
    </Page >
  )
}

ObraEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraEdit))
)
export { connectedObraEditPage as ObraEdit }
