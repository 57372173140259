import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Table,
  TableRow,
  TableCell,
  Skeleton,
  Typography
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import { obraMedicaoEtapaItemAnexoAction, obraMedicaoAction } from '../../_actions'

export const ObraMedicaoEditModalAnexo = ({ modal, toggle, props, idObraMedicaoEtapaItem, idObraMedicao }) => {

  const dispatch = useDispatch()
  const { classes, className, ...rest } = props
  const { match: { params } } = props;
  const { isLoading } = props.obraMedicaoEtapaItemAnexo

  const obraMedicaoEtapaItemAnexo = useSelector(
    (state) => state.obraMedicaoEtapaItemAnexo.obraMedicaoEtapaItemAnexo
  )

  const handleClose = () => {
    toggle();
  }

  useEffect(() => {
    if (idObraMedicaoEtapaItem) {
      dispatch(obraMedicaoEtapaItemAnexoAction.getObraMedicaoEtapaItemAnexos(idObraMedicaoEtapaItem))
    }

    if (idObraMedicao) {
      dispatch(obraMedicaoAction.getObraMedicaoById(idObraMedicao))
    }
  }, [])

  const handleOpenImagem = (link) => {
    window.open(link);
  }

  return (
    <Dialog
      open={modal}
      fullWidth
      style={{ padding: 0, margin: 0 }}
      maxWidth='md'
      onClose={toggle}
    >
      <DialogTitle>
        <Grid alignItems="flex-end" container justifyContent="space-between" spacing={3}>
          <Grid item>
            <Typography style={{ fontSize: "16px", fontWeight: 'bold', textAlign: "left", marginRight: 5 }}>
              ANEXOS
            </Typography>
          </Grid>
          <Button
            variant="contained"
            size="small"
            style={{ backgroundColor: '#000', position: 'relative', marginTop: 15 }}
            onClick={() => handleClose()}>
            <CloseIcon />
          </Button>
        </Grid>
      </DialogTitle>
      <DialogContent style={{ padding: 0, margin: 0 }}>
        <Card style={{ marginTop: 0, paddingLeft: 8, paddingRight: 8, paddingBottom: 8 }}>
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
          >
            <Table fullWidth>
              {undefined !== obraMedicaoEtapaItemAnexo && obraMedicaoEtapaItemAnexo.length
                ? obraMedicaoEtapaItemAnexo.map((n) => {
                  return (
                    n.caminhoArquivo.endsWith('png') || n.caminhoArquivo.endsWith('jpg') || n.caminhoArquivo.endsWith('jpeg') || n.caminhoArquivo.endsWith('webp') ?
                      <TableRow style={{ margin: 4, justifyContent: 'center' }} key={n.id} >
                        <TableCell align="center">
                          <img
                            style={{ borderRadius: 10, backgroundSize: 'cover', width: 180, height: 160, cursor: 'pointer' }}
                            src={n.caminhoArquivo}
                            onClick={() => handleOpenImagem(n.caminhoArquivo)}
                            alt={n.caminhoArquivo}
                            loading="lazy"
                          />
                        </TableCell>
                      </TableRow>
                      :
                      <TableRow style={{ margin: 4 }} key={n.id}>
                        <TableCell align="center">
                          <span style={{ cursor: 'pointer' }} onClick={() => handleOpenImagem(n.caminhoArquivo)}>{n.caminhoArquivo}</span>
                        </TableCell>
                      </TableRow>
                  )
                })
                :
                (
                  !isLoading ?
                    <TableRow>
                      <TableCell rowSpan={3} colSpan={2} align="center">Nenhum registro encontrado</TableCell>
                    </TableRow>
                    :
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                )
              }
            </Table>
          </Grid>
        </Card >
      </DialogContent >
    </Dialog >
  )
}
