import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { obraAction } from '../../../../_actions'
import VisibilityIcon from '@mui/icons-material/Visibility';
import ConstructionIcon from '@mui/icons-material/Construction';
import {
  Avatar,
  Button,
  Fade,
  Grid,
  IconButton,
  Input,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography
} from '@mui/material';
import PropTypes from 'prop-types'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { useSelector, useDispatch } from 'react-redux'
import { AccessControl } from '../../../../components/Utils/AccessControl'
import { STATUS_SERVICO, history } from '../../../../utils'
import { useDebounce } from 'use-debounce';
import Stars from '../../../../components/Stars/Stars';

const ToolTipFormatted = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 550,
    fontSize: 9,
    border: '1.2px solid #90b0cb',
  },
}));

const GridStyled = styled(Grid)(() => ({
  marginBottom: 8,
  padding: 2
}));

const SpanStyled = styled('span')({
  display: 'flex',
  flexDirection: 'row',
  alignContent: 'center'
});

const AvatarStyled = styled(Avatar)(() => ({
  width: 93,
  height: 93,
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center'
}));

const ButtonStyled = styled(Button)(() => ({
  paddingLeft: 5,
  paddingTop: 2,
  paddingBottom: 2,
  paddingRight: 5,
  backgroundColor: '#1C5585',
  color: '#FFF',
  '&:hover': {
    backgroundColor: '#000',
    transition: '0.2s'
  },
}));

const CardListaObraInteresse = (props) => {

  const dispatch = useDispatch()
  const { user } = useSelector(
    (state) => state.authentication)

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [term, setTerm] = useState('');
  const [debounceTerm] = useDebounce(term, 1000);
  const [order] = useState('data');
  const [direction] = useState('desc');

  const { obra, totalRegistros, isLoading } = useSelector(
    (state) => state.obra
  )

  const retornaStatusObra = (ultimoEvento) => {
    if (ultimoEvento == STATUS_SERVICO.LANCAMENTO) {
      return <span style={{ backgroundColor: '#000000', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        EM LANÇAMENTO
      </span>
    } else if (ultimoEvento == STATUS_SERVICO.PENDENTE) {
      return <span style={{ backgroundColor: '#9E9E9E', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        PENDENTE
      </span>
    } else if (ultimoEvento == STATUS_SERVICO.RECEBENDO_ORCAMENTOS) {
      return <span style={{ backgroundColor: '#673AB7', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        RECEBENDO ORÇAMENTOS
      </span>
    } else if (ultimoEvento == STATUS_SERVICO.ORCAMENTO_APROVADO) {
      return <span style={{ backgroundColor: '#2196F3', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        EM EXECUÇÃO
      </span>
    } else if (ultimoEvento == STATUS_SERVICO.RECUSADO) {
      return <span style={{ backgroundColor: '#ef5350', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        RECUSADA
      </span>
    } else if (ultimoEvento == STATUS_SERVICO.CONCLUIDO) {
      return <span style={{ backgroundColor: '#4CAF50', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        CONCLUÍDA
      </span>
    } else if (ultimoEvento == STATUS_SERVICO.AVALIADO) {
      return <span style={{ backgroundColor: '#FF9800', borderRadius: 8, padding: 4, color: '#fff', fontSize: 12, marginRight: 4, fontWeight: 400 }}>
        AVALIADA
      </span>
    }
  }

  useEffect(() => {

    const fetchData = (page, limit, term) => {

      let filterModel = {
        Page: page,
        Limit: limit,
        Term: term,
        Order: order,
        Direction: direction,
        Status: STATUS_SERVICO.RECEBENDO_ORCAMENTOS
      };

      dispatch(obraAction.getObra(filterModel, true));
    };

    fetchData(page, limit, debounceTerm, order, direction);

  }, [page, limit, debounceTerm, order, direction])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeLimit = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChange = prop => event => {
    setTerm(event.target.value);
  };

  return (
    <>
      <Typography variant="h6" gutterBottom align="left" style={{ marginTop: 10, marginBottom: 5 }}>Obras de Interesse</Typography>
      <Grid item xl={3} lg={3} md={3} xs={12}>
        <Input
          id="term"
          label="term"
          placeholder="Filtro"
          value={term}
          fullWidth
          onChange={handleChange('term')}
        />
      </Grid>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell><strong>TÍTULO</strong></TableCell>
              <TableCell align="center"><strong>TIPO DE OBRA</strong></TableCell>
              <TableCell align="center"><strong>ÓRGÃO</strong></TableCell>
              <TableCell align="center"><strong>NOTA</strong></TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {obra && obra.length > 0 ? obra.map((o) => (
              <TableRow
                hover
                key={o.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {o.urgencia ? (
                      <span style={{ backgroundColor: '#ef5350', borderRadius: 8, padding: 4, color: '#fff', fontSize: 13, marginRight: 4, fontWeight: 400 }}>
                        OBRA URGENTE
                      </span>
                    ) : null}
                    <ToolTipFormatted
                      placement="top-end"
                      TransitionComponent={Fade}
                      title={
                        <GridStyled container>
                          <Grid item lg={12} md={12} xl={12} xs={12}>
                            <Typography color="inherit" align="center"><b>Detalhes da Obra</b></Typography>
                          </Grid>
                          <Grid container>
                            <Grid item lg={3} md={3} xl={3} xs={12} align="center">
                              {o.caminhoImagem ?
                                <AvatarStyled variant="rounded"
                                  src={o.caminhoImagem}
                                />
                                :
                                <AvatarStyled variant="rounded">
                                  <ConstructionIcon sx={{ fontSize: 40 }} />
                                </AvatarStyled>
                              }
                            </Grid>
                            <Grid item lg={9} md={9} xl={9} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
                              <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Título:</b>&nbsp;{o.titulo?.toUpperCase()}</Typography></SpanStyled>
                              <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Descritivo:</b>&nbsp;{o.descritivo?.toUpperCase()}</Typography></SpanStyled>
                              <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Status:</b>&nbsp;{retornaStatusObra(o.ultimoEvento)}</Typography></SpanStyled>
                              <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>BDI:</b>&nbsp;{o.bdi ? o.bdi + '%' : 'SEM BDI'}</Typography></SpanStyled>
                              <SpanStyled><Typography sx={{ fontSize: 12 }} color="inherit" align="left"><b>Valor referência:</b>&nbsp;R$ {o.valorReferenciaComBdi ? o.valorReferenciaComBdi.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '0,00'}</Typography></SpanStyled>
                            </Grid>
                          </Grid>
                          <Grid item lg={12} md={12} xl={12} xs={12} align="center">
                            <ButtonStyled onClick={() => history.push(`/obra/` + o.id)} size="small">Ver mais</ButtonStyled>
                          </Grid>
                        </GridStyled>
                      }
                    >
                      <span style={{ verticalAlign: 'middle' }}> {o.titulo ? `#${o.id} ` + o.titulo.toUpperCase() : ''}</span>
                    </ToolTipFormatted>
                  </div>
                </TableCell>
                <TableCell align="center">{o.tipoObra?.nome?.toUpperCase()}</TableCell>
                <TableCell align="center">{o.orgao?.nome?.toUpperCase()}</TableCell>
                <TableCell align="center">{<Stars mediaNota={o.obrasAvaliacoes && o.obrasAvaliacoes[0]?.nota} avaliacoes={1} />}</TableCell>
                <TableCell align="center"><AccessControl
                  rule={'obras.list'} //permissão necessária para acessar conteúdo
                  yes={() => (
                    <IconButton
                      aria-label="Visualizar"
                      component="a"
                      onClick={() =>
                        history.push(`/obra/${o.id}`)
                      }
                      size="small"
                    >
                      <Tooltip title={'Visualizar obra'}>
                        <Tooltip >
                          <VisibilityIcon />
                        </Tooltip>
                      </Tooltip>
                    </IconButton>
                  )}
                /></TableCell>
              </TableRow>
            )) :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={5} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  Array.apply(null, { length: 5 }).map((e, i) => (
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
              )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalRegistros}
        labelRowsPerPage="Linhas por página"
        labelDisplayedRows={({ from, to }) => `${from}-${to} de ${totalRegistros}`}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={limit}
        onRowsPerPageChange={handleChangeLimit}
      />
    </>
  );
}

CardListaObraInteresse.propTypes = {
  className: PropTypes.string,
  subtitle: PropTypes.string
}

export default CardListaObraInteresse
