import React, { useEffect, useState } from 'react';
import moment from 'moment'
import { styled } from '@mui/material/styles';
import { AccessControl } from '../../components/Utils/AccessControl'
import { useLocation } from 'react-router-dom';
import CircleIcon from '@mui/icons-material/Circle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { crudService, uploadFile } from '../../_services';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import {
  Autocomplete,
  Backdrop,
  Box,
  CardHeader,
  Checkbox,
  CircularProgress,
  Divider,
  FormLabel,
  Grid,
  Input,
  IconButton,
  Paper,
  Skeleton,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableContainer,
  TextField,
  Typography,
  Tooltip
} from '@mui/material'
import { obraCobrancaAction, obraAction, authActions, empenhoAction } from '../../_actions';
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useConfirm } from 'material-ui-confirm'
import { withRouter, } from 'react-router-dom'
import { withStyles } from '@mui/styles'
import CustomButton from '../../components/CustomButton/CustomButton'
import { ModalCronograma } from './ModalCronograma.component';
import { history, MANUAIS_CATEGORIAS } from '../../utils'
import HelpIcon from '@mui/icons-material/Help';
import { HelpModal } from '../../components';
import CardTableFinanceiro from './cardTableFinanceiro.component'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(2)
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    textAlgin: 'center'
  },
  nome: {
    marginTop: theme.spacing(1)
  },
  uploadButton: {
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  avatar: {
    height: 200,
    width: 200,
    borderRadius: '10px',
    webkitBorderRadius: '10px',
    mozBorderRadius: '10px',
    cursor: 'pointer'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  }
})

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: '#015192'
  },
}));

const ObraFinanceiroEdit = ({ props, idObra }) => {

  const { isLoading } = props.obraCobranca;
  const confirm = useConfirm()
  const { classes } = props

  const [valueEmpresa, setValueEmpresa] = React.useState(null);
  const [inputValueEmpresa, setInputValueEmpresa] = React.useState('');
  const [empresas, setEmpresas] = useState([]);
  const [openNf, setOpenNf] = useState(false)

  const { obraEtapa } = props.obraEtapa
  const { empresa } = useSelector(
    (state) => state.empresa
  )

  const [modalHelp, setModalHelp] = useState(false);
  const toggleModalHelp = () => setModalHelp(!modalHelp);

  const handleOpenModalHelp = () => {
    toggleModalHelp();
  }

  const optionsEmpresa = empresas?.map(u => ({ id: u.id, nome: u.nome }));

  const { match: { params } } = props;

  const location = useLocation();
  const empresaListar = location.state ? location.state.empresa : null;
  const empenhos = useSelector((state) => state.obra?.obrasEmpenhos)
  const { obraCobranca } = props.obraCobranca;
  const [totalValorOrcamentosAprovados, setTotalValorOrcamentosAprovados] = useState(null);
  const [pagarEmpenho, setPagarEmpenho] = useState(false)
  const [idEmpenho, setIdEmpenho] = useState(null)

  const [ready, setReady] = React.useState(false);
  const dispatch = useDispatch()

  const { idOrgao } = useSelector(
    (state) => state.usuarioOrgao
  )

  useEffect(() => {
    if (props.authentication.user.idTipoUsuario == 3) {
      setEmpresas([{ id: props.authentication.user.usuariosEmpresas[0].idEmpresa, nome: props.authentication.user.usuariosEmpresas[0].empresa.razaoSocial }]);
    } else {
      crudService
        .get(`obrasOrcamentosItens/itensAprovados/${idObra}`)
        .then(response =>
          setEmpresas(response.data?.map(r => {
            return { id: r.idEmpresa, nome: r.empresa }
          })));
    }
  }, [])

  useEffect(() => {
  }, [ready, isLoading])

  useEffect(() => {
    setTotalValorOrcamentosAprovados(props.obra?.obrasOrcamentos
      ?.filter(item => item.status == 1) // Filtra apenas os itens com status 1
      .reduce((acc, item) => acc + item.valor, 0)); // Soma o valor desses itens
  }, [props.obra?.obrasOrcamentos]);

  useEffect(() => {

    if (valueEmpresa != null && valueEmpresa != '') {
      let filterModel = {
        IdObra: idObra,
        IdEmpresa: valueEmpresa.id
      };

      dispatch(obraCobrancaAction.getObraCobranca(filterModel))
    }
  }, [valueEmpresa])

  useEffect(() => {

    if (empresaListar) {
      let filterModel = {
        IdObra: idObra,
        IdEmpresa: empresaListar.id
      };

      dispatch(obraCobrancaAction.getObraCobranca(filterModel))

      setValueEmpresa({ id: empresaListar.id, nome: empresaListar.razaoSocial });
      setInputValueEmpresa({ id: empresaListar.id, nome: empresaListar.razaoSocial });
    }

  }, [empresaListar])

  useEffect(() => {
    if (pagarEmpenho && idEmpenho) {
      realizarPagamentoEmpenho()
    }
  }, [props.empenho.caminhoNotaFiscal, pagarEmpenho]);

  const isItemPaid = (id) => {
    if (id != undefined) {
      return obraCobranca?.find((n) => n.dataPagamento != null && n.id == id)
    }
  }

  const handleOpenVisualiza = (link) => {
    window.open(link, '_blank');
  }

  const isEmpenhoPaid = () => {
    if (empenhos) {
      return empenhos?.some((n) => n.empenho?.dataPagamento != null && n.idObra == parseInt(idObra) && n.excluido != true)
    }
  }

  const verifyObraHasEmpenho = () => {
    return empenhos?.some(ts => ts.idObra == parseInt(idObra) && ts.excluido != true);
  }

  const totalEmAbertoFinanceiro = obraCobranca?.reduce((total, item) => {
    if (!item.dataPagamento) {
      return total + item.valor;
    }
    return total;
  }, 0);

  const totalPagoFinanceiro = obraCobranca?.reduce((total, item) => {
    if (item.dataPagamento) {
      return total + item.valor;
    }
    return total;
  }, 0);

  const totalCobrancasEmitidasFinanceiro = obraCobranca?.reduce((total, item) => {
    if (item) {
      return total + item.valor;
    }
    return total;
  }, 0);
  function TitleTextProgresso() {
    return (
      <Typography variant="h5">
        <b>{'Progresso Financeiro da Obra'}</b>
      </Typography>
    )
  }

  function SegHeaderProgresso() {
    return <TitleTextProgresso />
  }

  function TitleTextEmpenho() {
    return (
      <Typography variant="h5">
        <b>{'Empenho'}</b>
      </Typography>
    )
  }
  function SegHeaderEmpenho() {
    return (
      <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
        <TitleTextEmpenho />
      </Box>
    )

  }

  const handleOpenVisualizaNF = (link) => {
    window.open(link, '_blank');
  }

  const handleRealizaPagamento = (id) => {
    confirm({
      title: 'Você deseja realizar o pagamento desta Cobrança?',
      description: 'Essa operação é irreversível',
      disabled: props.obraCobranca.isLoading,
      confirmationText: props.obraCobranca.isLoading ? 'Realizando pagamento...' : 'Sim, receber!',
      cancellationText: 'Não!'
    }).then(() => {
      if (id && id != undefined) {
        dispatch(obraCobrancaAction.editObraCobrancaPaga(id, idObra));
      }
    })
  }

  const handleRealizaPagamentoEmpenho = (id) => {
    confirm({
      title: 'Você deseja marcar este empenho como pago?',
      description: returnUploadNF(),
      disabled: props.empenho.isLoading,
      confirmationText: props.empenho.isLoading ? 'Marcando como pago...' : 'Sim!',
      cancellationText: 'Não!'
    }).then(() => {
      setPagarEmpenho(true)
      setIdEmpenho(id)
    })
  }

  const realizarPagamentoEmpenho = () => {
    if (idEmpenho && idEmpenho != 0) {
      dispatch(empenhoAction.editEmpenhoRealizaPagamento(idEmpenho, idOrgao, idObra, {
        caminhoNotaFiscal: props.empenho.caminhoNotaFiscal
      }));
    }
  }

  const handleUploadNotaFiscal = (e) => {
    setOpenNf(true)
    var file = e.target.files[0]
    if (file) {
      uploadFile(file)
        .then((response) => {
          var event2 = {
            type: 'select',
            target: {
              value: response.toString()
            }
          };
          dispatch(empenhoAction.onChangeProps('caminhoNotaFiscal', event2))
          setOpenNf(false)
        })
        .catch((err) => {
          console.log(err)
          setOpenNf(false)
        })
    }
  }

  function returnUploadNF() {
    return (
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xl={12} xs={12} align="left">
          <Backdrop className={classes.backdrop} open={openNf}>
            <Grid
              container
              spacing={3}
              direction="column"
              justifyContent="center"
              alignItems="center"
              alignContent="center"
              wrap="nowrap"
            >
              <Grid item>
                <CircularProgress color="primary" />
              </Grid>
              <Grid item>
                <Typography variant="h4" color="textPrimary">
                  Enviando nota fiscal...
                </Typography>
              </Grid>
            </Grid>
          </Backdrop>
          <FormLabel component="legend">Enviar Nota Fiscal</FormLabel>
          <Input
            id="caminhoNotaFiscal"
            className={classes.textField}
            label="Arquivo da NF"
            onChange={handleUploadNotaFiscal}
            type="file"
            placeholder="Enviar nota fiscal"
            formControl
            margin="dense"
          />
        </Grid>
      </Grid>
    )
  }

  function TitleText() {
    return (
      <Typography variant="h5" style={{ marginLeft: 10 }}>
        <b>{'Receber Cobranças'}</b>
      </Typography>
    )
  }
  function SegHeader() {
    return (
      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignContent: 'center', marginTop: 3 }}>
        <Grid alignItems="center" container justifyContent="center" spacing={3} style={{ marginBottom: 15 }}>
          <Grid item lg={6} sm={6} xl={6} xs={12}>
            <CardTableFinanceiro
              //se não tiver cobrança ainda
              valorEmAberto={`R$ ${obraCobranca?.length == 0 ?
                //pega o valor do orçamento que foi aprovado
                (props.obra?.obrasOrcamentos && props.obra?.obrasOrcamentos.length > 0) ?
                  (props.obra?.obrasOrcamentos.find(orc => orc.status == 1)?.valor ?? 0)
                    .toLocaleString('pt-br', { minimumFractionDigits: 2 }) :
                  //senão, pega o valor das cobranças que já foram emitidas pela empresa
                  (totalEmAbertoFinanceiro != 0 && totalEmAbertoFinanceiro != undefined) ?
                    totalEmAbertoFinanceiro.toLocaleString('pt-br', { minimumFractionDigits: 2 }) :
                    '0,00' :
                (totalEmAbertoFinanceiro != 0 && totalEmAbertoFinanceiro != undefined) ?
                  totalEmAbertoFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) :
                  '0,00'}`}
              valorTotal={totalCobrancasEmitidasFinanceiro == 0 ? 'R$ 0,00' : `R$ ${totalCobrancasEmitidasFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
              valorPago={`R$ ${totalPagoFinanceiro ? totalPagoFinanceiro?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : '0,00'}`}
              textValorTotal="Valor total das cobranças emitidas"
              title="RESUMO FINANCEIRO"
            />
          </Grid>
          {(authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) && (
            <Grid item lg={6} sm={6} xl={6} xs={12}>
              <CardTableFinanceiro
                valorEmAberto={!verifyObraHasEmpenho() ? 'R$ --,--' : !isEmpenhoPaid() ? `R$ ${totalValorOrcamentosAprovados?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : 'R$ 0,00'}
                valorTotal={!verifyObraHasEmpenho() ? 'R$ --,--' : `R$ ${totalValorOrcamentosAprovados?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`}
                valorPago={!verifyObraHasEmpenho() ? 'R$ --,--' : isEmpenhoPaid() ? `R$ ${totalValorOrcamentosAprovados?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}` : 'R$ 0,00'}
                textValorTotal="Valor total do empenho"
                title="RESUMO EMPENHO"
              />
            </Grid>
          )}
        </Grid>
        <Grid container style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} spacing={2}>
          <Grid item lg={3} md={3} xl={3} xs={12}>
            <TitleText />
          </Grid>
          <Grid item lg={1} md={1} xl={1} xs={12}>
            <IconButton
              aria-label="Ajuda"
              component="a"
              onClick={() =>
                handleOpenModalHelp()
              }
              size="small"
            >
              <Tooltip title={'Ajuda'}>
                <Tooltip>
                  <HelpIcon sx={{ float: 'center', color: '#000' }} fontSize='medium' />
                </Tooltip>
              </Tooltip>
            </IconButton>
          </Grid>
          <Grid item lg={8} md={8} xl={8} xs={12} align="right">
            <CustomButton
              style={{ backgroundColor: '#015192' }}
              onClick={() => dispatch(obraAction.handleModalCronograma())}
            >
              <Typography variant="p" color="#fff">Cronograma</Typography>
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <>
      <ModalCronograma />

      <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 5, alignItems: 'center', marginTop: 3 }}>
        <CardHeader
          style={{ width: '100%' }}
          subheader="Alterar o status de uma cobrança"
          title={<SegHeader />}
        />
        {(authActions.isEmpresa() && obraEtapa?.length) &&
          <Grid item>
            <CustomButton
              size="small"
              variant="outlined"
              style={{ verticalAlign: 'middle' }}
              onClick={() => history.push(`/obraCobranca/`, idObra)}
              endIcon={<MonetizationOnIcon />}
            >
              Cadastrar nova cobrança
            </CustomButton>
          </Grid>
        }
      </Grid>
      {props.authentication.user.idTipoUsuario != 3 &&
        <Grid item lg={12} md={12} xl={12} xs={12} mb={2}>
          <Autocomplete
            value={valueEmpresa}
            onChange={(event, newValue) => {
              setValueEmpresa(newValue);
            }}
            style={{ marginTop: 16 }}
            inputValue={inputValueEmpresa}
            onInputChange={(event, newInputValue) => {
              setInputValueEmpresa(newInputValue);
            }}
            id="controllable-states-demo"
            options={optionsEmpresa}
            isOptionEqualToValue={(option, value) => option.id == value.id}
            margin="normal"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            defaultValue={valueEmpresa}
            getOptionLabel={option => option.nome || ''}
            renderInput={(params) => <TextField {...params} label="Selecione uma empresa para listar suas cobranças..." fullWidth required />}
            fullWidth
          />
        </Grid>
      }

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
              <TableCell size="small">
                <strong>DATA</strong>
              </TableCell>
              <TableCell size="small">
                <strong>EMPRESA</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>NOTA FISCAL</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>VALOR</strong>
              </TableCell>
              <TableCell size="small" align="center">
                <strong>STATUS</strong>
              </TableCell>
              <TableCell size="small" align="center">

              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {undefined !== obraCobranca && obraCobranca.length && valueEmpresa && valueEmpresa != null
              ? obraCobranca.map((n) => {
                return (
                  <TableRow hover key={n.id}>
                    <TableCell component="th" scope="row">
                      {n.dataCobranca ? moment(n.dataCobranca).format("DD/MM/YYYY HH:mm") : ''}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {n.empresa ? n.empresa.razaoSocial.toUpperCase() : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {n.caminhoNota ?
                        <CustomButton
                          variant="outlined"
                          size="small"
                          style={{ justifyContent: 'center' }}
                          onClick={() => handleOpenVisualizaNF(n.caminhoNota)}
                        >
                          Visualizar
                        </CustomButton>
                        :
                        null}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      {n.valor ? 'R$' + n.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : ''}
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <Tooltip
                        title={n.dataPagamento != null ? `Pago em ${moment(n.dataPagamento).format("DD/MM/YYYY HH:mm")}` : false}
                      >
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}> <CircleIcon sx={{ color: n.dataPagamento == null ? '#cf0d0d' : '#0d6e0d', fontSize: 12, marginRight: 1 }} /> <span>{n.dataPagamento == null ? 'EM ABERTO' : 'PAGO'}</span></div>
                      </Tooltip>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <AccessControl
                        rule={'obrasCobrancas.pay'} //permissão necessária para acessar conteúdo
                        yes={() => (
                          <Checkbox
                            onClick={() => handleRealizaPagamento(n.id)}
                            color="primary"
                            size="small"
                            disabled={isItemPaid(n.id)}
                            checked={isItemPaid(n.id)}
                          />
                        )}
                      />
                    </TableCell>
                  </TableRow >
                )
              })
              :
              (
                !isLoading ?
                  <TableRow>
                    <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                  </TableRow>
                  :
                  Array.apply(null, { length: 5 }).map((e, i) => (
                    <TableRow hover>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Skeleton animation="wave" />
                      </TableCell>
                    </TableRow>
                  ))
              )
            }
          </TableBody>
        </Table>
        <Divider />
      </TableContainer>
      <Grid container spacing={2} style={{ marginTop: 10 }}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <CardHeader
            subheader="Exibe o percentual de pagamento em relação a todos os itens da obra que receberam cobrança"
            title={<SegHeaderProgresso />}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 3, ml: 1 }}>
              <BorderLinearProgress variant="determinate" value={props.obra.percentualObraOrcamentosPagos != 0 ? props.obra.percentualObraOrcamentosPagos : 0} />
            </Box>
            <Box sx={{ minWidth: 38 }}>
              <Typography variant="body1" color="text.secondary">{`${props.obra.percentualObraOrcamentosPagos != 0 ? props.obra.percentualObraOrcamentosPagos : 0}%`}</Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {(authActions.isGestor() || authActions.isPrefeitura() || authActions.isEmpresa()) && (
        <>
          <CardHeader
            subheader="Informações do empenho desta Obra"
            title={<SegHeaderEmpenho />}
          />
          <Grid item lg={12} md={12} xl={12} xs={12} align="left">
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow size="small" style={{ backgroundColor: '#f2f2f2' }}>
                    <TableCell size="small">
                      <strong>DATA</strong>
                    </TableCell>
                    <TableCell size="small" align="center">
                      <strong>NOTA FISCAL</strong>
                    </TableCell>
                    <TableCell size="small" align="center">
                      <strong>ARQUIVO</strong>
                    </TableCell>
                    <TableCell size="small" align="center">
                      <strong>VALOR</strong>
                    </TableCell>
                    <TableCell size="small" align="center">
                      <strong>STATUS</strong>
                    </TableCell>
                    <TableCell size="small" align="center">

                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {empenhos && undefined !== empenhos && empenhos.length
                    ? empenhos.map((n) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            {n.empenho && n.empenho.dataCadastro ? moment(n.empenho.dataCadastro).format("DD/MM/YYYY HH:mm") : ''}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {n.empenho && n.empenho.caminhoNotaFiscal ?
                              <CustomButton
                                variant="outlined"
                                size="small"
                                style={{ justifyContent: 'center' }}
                                onClick={() => handleOpenVisualiza(n.empenho.caminhoNotaFiscal)}
                              >
                                Visualizar
                              </CustomButton>
                              :
                              '-'}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {n.empenho && n.empenho.caminhoArquivo ?
                              <CustomButton
                                variant="outlined"
                                size="small"
                                style={{ justifyContent: 'center' }}
                                onClick={() => handleOpenVisualiza(n.empenho.caminhoArquivo)}
                              >
                                Visualizar
                              </CustomButton>
                              :
                              '-'}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            {n.empenho && n.empenho.valor && n.empenho.valor != 0
                              ? 'R$' + n.empenho.valor.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                              : (totalValorOrcamentosAprovados
                                ? 'R$' + totalValorOrcamentosAprovados.toLocaleString('pt-br', { minimumFractionDigits: 2 })
                                : '-')}
                          </TableCell>
                          <TableCell component="th" scope="row" align="center">
                            <Tooltip
                              title={n.empenho && n.empenho.dataPagamento != null ? `Pago em ${moment(n.empenho.dataPagamento).format("DD/MM/YYYY HH:mm")}` : false}
                            >
                              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}> <CircleIcon sx={{ color: n.empenho && n.empenho.dataPagamento == null ? '#cf0d0d' : '#0d6e0d', fontSize: 12, marginRight: 1 }} /> <span>{n.empenho && n.empenho.dataPagamento == null ? 'EM ABERTO' : n.empenho ? `PAGO EM ${moment(n.empenho?.dataPagamento).format("DD/MM/YYYY HH:mm")}` : null}</span></div>
                            </Tooltip>
                          </TableCell>
                          <TableCell align="center">
                            <AccessControl
                              rule={'empenhos.pay'} //permissão necessária para acessar conteúdo
                              yes={() => (
                                <Tooltip title={'Receber pagamento'}>
                                  <Tooltip >
                                    <Checkbox
                                      onClick={() => handleRealizaPagamentoEmpenho(n.empenho.id)}
                                      color="primary"
                                      size="small"
                                      disabled={isEmpenhoPaid(n.empenho.id)}
                                      checked={isEmpenhoPaid(n.empenho.id)}
                                    />
                                  </Tooltip>
                                </Tooltip>
                              )}
                            />
                          </TableCell>
                        </TableRow >
                      )
                    })
                    :
                    (
                      !isLoading ?
                        <TableRow>
                          <TableCell rowSpan={3} colSpan={6} align="center">Nenhum registro encontrado</TableCell>
                        </TableRow>
                        :
                        Array.apply(null, { length: 5 }).map((e, i) => (
                          <TableRow hover>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Skeleton animation="wave" />
                            </TableCell>
                          </TableRow>
                        ))
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      )}
      {
        (modalHelp ?
          <HelpModal
            toggle={toggleModalHelp}
            modal={modalHelp}
            props={props}
            tela={MANUAIS_CATEGORIAS.ABA_OBRA_FINANCEIRO}
          />
          :
          null
        )
      }
    </>
  );
};

ObraFinanceiroEdit.propTypes = {
  classes: PropTypes.object.isRequired
}
const mapStateToProps = (state) => {
  return state
}

const connectedObraFinanceiroEditPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(ObraFinanceiroEdit))
)
export { connectedObraFinanceiroEditPage as ObraFinanceiroEdit }

export default ObraFinanceiroEdit;
